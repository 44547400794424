.ms-app-ser-container {
    width: 1532px;
    height: auto;
    margin: 0px auto;
    /* display: flex; */
}

.ms-app-ser-section1-container {
    width: 100%;
    height: 840px;
    background-color: #24688E1a;
    display: flex;
}

.ms-app-ser-section1-content {
    width: 566px;
    height: 324px;
    margin: 198px 0px 0px 90px;
    /* border: 1px solid#000; */
}

.ms-app-ser-section1-content h3 {
    width: 566px;
    height: auto;
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.36px;
    text-align: left;
    margin: 0px;
}

.ms-app-ser-section1-content p {
    width: 501px;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin: 20px 0px 0px 0px;
}

.wad-page-contact-us-container-button-2 {
    width: 176px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
    display: flex;
    border: none;
    margin: 20px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wad-page-contact-us-container-button-2 p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #FFFFFF;
}

.wad-page-contact-us-container-button-2 img {
    margin: 0px 0px 0px 10px;
    width: 18px;
    height: auto;
}

.ms-app-ser-section1-image {
    width: 660px;
    height: 444px;
    margin: 198px 90px 0px 126px;
}

.ms-app-ser-section2-container {
    width: 100%;
    height: 868px;
    display: flex;
    position: relative;
}

.ms-app-ser-section2-image {
    width: 100%;
    height: 868px;
    position: relative;
    margin: 0px;

}

.ms-app-ser-section2-image img {
    width: 1532px;
    height: 868px;
    object-fit: fill;
}

.ms-app-ser-section2-content {
    width: 1532px;
    height: 868px;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 1;
    /* margin: 0px; */
    display: flex;
}

.ms-app-ser-section2-content-left-div {
    width: 586px;
    height: 457px;
    /* border: 1px solid #FFFFFF; */
    margin: 206px 0px 0px 90px;
}

.ms-app-ser-section2-content-left-div h3 {
    width: 586px;
    height: 108px;
    font-size: 36px;
    letter-spacing: 0.36px;
    line-height: 54px;
    color: #FFFFFF;
    font-weight: bold;
    margin: 0px;
    text-align: left;
}

.ms-app-ser-section2-content-left-div p {
    width: 574px;
    height: auto;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #FFFFFF;
    font-weight: 400;
    margin: 43px 0px 0px 0px;
    text-align: left;
}

.wad-page-section2-container-button {
    width: 353px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
    display: flex;
    margin: 43px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wad-page-section2-container-button p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #FFFFFF;
}

.wad-page-section2-container-button img {
    margin: 0px 0px 0px 10px;
    width: 18px;
    height: auto;
}

.ms-app-ser-section2-content-right-div {
    width: 660px;
    height: 670px;
    margin: 99px 90px 0px 106px;
    /* border: 2px solid #FFF; */
}

.ms-app-ser-section2-content-right-div-grid {
    width: 660px;
    height: 200px;
    background-color: #FFFFFF;
    margin: 0px 0px 35px 0px;
    display: flex;
    flex-direction: column;
}

.ms-app-ser-section2-content-right-div-grid h4 {
    width: auto;
    height: 45px;
    font-size: 30px;
    letter-spacing: 0.3px;
    line-height: 45px;
    color: #000000;
    font-weight: semi-bold;
    margin: 20px 0px 0px 30px;
}

.ms-app-ser-section2-content-right-div-grid p {
    width: 600px;
    height: auto;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #000000;
    font-weight: 300;
    margin: 20px auto 0px auto;
}

.ms-app-ser-section3-container {
    width: 100%;
    height: 667px;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to right, #DEDDDC 0%, rgb(166, 123, 82, 0.6) 100%);
}

.ms-app-ser-section3-container h3 {
    width: 430px;
    height: 48px;
    margin: 70px auto 0px auto;
    font-size: 36px;
    color: #000000;
    font-weight: bold;
    line-height: 48px;
    letter-spacing: 0.36px;

}

.ms-app-ser-section3-container h3 span {
    color: #000000;
}

.ms-app-ser-section3-container p {
    width: 840px;
    height: auto;
    margin: 50px auto 0px auto;
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: center;
}

.ms-app-ser-tecnhology-div {
    width: 990px;
    height: 50px;
    margin: 60px auto 0px auto;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #000; */
}

.tech-category {
    width: 150px;
    height: 50px;
    background: #000000;
    margin: 0px 0px 0px 0px;
    display: flex;
    /* cursor: pointer; */
}

.tech-category p {
    width: auto;
    height: 30px;
    font-size: 24px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 30px;
    letter-spacing: 0.24px;
    margin: auto;
}

.active-tech-category {
    background: #FFFFFF;
}

.active-tech-category p {
    color: #000000;
}

.ms-app-ser-technology-techskills {
    width: 1122px;
    height: 166px;
    background: rgb(0, 0, 0, 0.3);
    margin: 90px auto 0px auto;
}

.skills-list {
    width: auto;
    height: 50px;
    margin: auto;
    display: flex;
    justify-content: center;

}

.tech-skill {
    width: auto;
    height: 50px;
    background: #FFFFFF;
    display: flex;
    margin: 55px 40px 0px 0px;
}

.tech-skill p {
    color: #000;
    width: auto;
    height: auto;
    margin: auto 10px auto 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: center;
}

.tech-skill img {
    width: auto;
    height: 20px;
    margin: 15px 10px 0px 0px;
}

.ms-app-ser-section4-container {
    width: 100%;
    height: 841px;
    display: flex;
}

.ms-app-ser-section4-image {
    width: 1532px;
    height: 841px;
    position: relative;
}

.ms-app-ser-section4-image img {
    width: 1532px;
    height: 841px;
    object-fit: fill;
}

.ms-app-ser-section4-content-layer {
    width: 1352px;
    height: 578px;
    margin: 80px 90px 0px 90px;
    position: absolute;
    z-index: 1;
    display: flex;
    /* border: 1px solid #FFFFFF; */
}
.ms-app-ser-section4-content {
    width: 1352px;
    height: 578px;
    /* margin: 80px 90px 0px 90px; */
    /* position: absolute;
    z-index: 1; */
    display: flex;
    flex-direction: row;
    /* border: 1px solid #FFFFFF; */
}

.contact-us-form-container {
    width: 706px;
    height: 578px;
    margin: 0px 0px;
    display: flex;
}

.contact-us-form-container {
    margin: 0px 0px 0px 0px;
    background: rgb(255, 255, 255, 0.4);
    height: fit-content;
}

.ms-app-ser-section4-right-div {
    width: 543px;
    height: 424px;
    margin: auto 90px auto 103px;
    /* border: 1px solid #FFFFFF; */
}

.ms-app-ser-section4-right-div h3 {
    width: 495px;
    height: 48px;
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.36px;
    text-align: left;
    margin: 20px auto 0px auto;
    color: #FFFFFF;
    text-align: center;
}

.ms-app-ser-section4-right-div p {
    width: 450px;
    height: auto;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin: 20px auto 45px auto;
    color: #FFFFFF;
    text-align: left;
}

@media only screen and (max-width: 432px) {

    .ms-app-ser-container {
        /* max-width: 432px; */
        width: 100%;
        height: auto;
        margin: 0px auto 0px auto;
        /* display: flex; */
    }

    .ms-app-ser-section1-container {
        width: 100% ;
        height: 591px;
        background-color: #24688E1a;
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
    }
    .ms-app-ser-section1-image {
        width: 330px !important;
        height: 222px !important;
        margin: 30px auto 0px auto;
    }

    .ms-app-ser-section1-image img {
        width: 330px;
        height: 222px;
        /* margin: 30px auto 0px auto; */
    }
    .ms-app-ser-section1-content {
        width: 300px;
        height: auto;
        margin: 30px auto 0px auto;
    }
  
    .ms-app-ser-section1-content h3 {
        width: 300px;
        height: 64px;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.24px;
        text-align: left;
        margin: 0px;
    }

    .ms-app-ser-section1-content p {
        width: 300px;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.1px;
        text-align: left;
        margin: 10px 0px 0px 0px;
    }

    .wad-page-contact-us-container-button-2 {
        width: 85px !important;
        height: 30px !important;
        border-radius: 2px;
        background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
        display: flex;
        margin: 20px 0px 0px 0px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
    }

    .wad-page-contact-us-container-button-2 p {
        font-weight: bold;
        font-size: 10px !important;
        letter-spacing: 0.1px;
        width: 60px !important;
        height: auto;
        text-align: center;
        padding: 0px !important;
        margin: auto 0px auto 5px !important;
        color: #FFFFFF;
    }

    .wad-page-contact-us-container-button-2 img {
        margin: 0px 0px 0px 5px;
        width: 10px;
        height: auto;
    }


    
    .ms-app-ser-section2-container {
        width: 100%;
        height: 895px;
        display: flex;
        position: relative;
    }

    .ms-app-ser-section2-image {
        width: 100%;
        height: 895px;
        position: relative;
        margin: 0px;

    }

    .ms-app-ser-section2-image img {
        width: 100%;
        height: 895px;
        object-fit: fill;
    }

    .ms-app-ser-section2-content {
        width: 100%;
        height: 895px;
        background: rgb(0, 0, 0, 0.7);
        position: absolute;
        z-index: 1;
        /* margin: 0px; */
        display: flex;
        flex-direction: column;
    }

    .ms-app-ser-section2-content-left-div {
        width: 284px;
        height: 214px;
        /* border: 1px solid #FFFFFF; */
        margin: 66px auto 0px auto;
    }

    .ms-app-ser-section2-content-left-div h3 {
        width: 284px;
        height: 84px;
        font-size: 24px;
        letter-spacing: 0.24px;
        line-height: 28px;
        color: #FFFFFF;
        font-weight: bold;
        margin: 0px;
        text-align: left;
    }

    .ms-app-ser-section2-content-left-div p {
        width: 284px;
        height: auto;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 15px;
        color: #FFFFFF;
        font-weight: 400;
        margin: 20px 0px 0px 0px;
        text-align: left;
    }

    .ms-app-ser-section2-content-right-div {
        width: 330px;
        height: 430px;
        margin: 100px auto 0px auto;
        /* border: 2px solid #FFF; */
    }

    .ms-app-ser-section2-content-right-div-grid {
        width: 330px;
        height: 120px;
        background-color: #FFFFFF;
        margin: 0px 0px 35px 0px;
        display: flex;
        flex-direction: column;
    }

    .ms-app-ser-section2-content-right-div-grid h4 {
        width: auto;
        height: auto;
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 24px;
        color: #000000;
        font-weight: semi-bold;
        margin: 10px auto 0px auto;
    }

    .ms-app-ser-section2-content-right-div-grid p {
        width: 255px;
        height: auto;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 15px;
        color: #000000;
        font-weight: 300;
        text-align: center;
        margin: 10px auto 0px auto;
    }
    .ms-app-ser-section3-container {
        width: 100%;
        height: 814px;
        display: flex;
        flex-direction: column;
        background: rgb(166, 123, 82, 0.25);
    }

    .ms-app-ser-section3-container h3 {
        width: 289px;
        height: 32px;
        margin: 50px auto 0px auto;
        font-size: 24px;
        color: #000000;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: 0.24px;

    }

    .ms-app-ser-section3-container h3 span {
        color: #000000;
    }

    .ms-app-ser-section3-container p {
        width: 300px;
        height: auto;
        margin: 25px auto 0px auto;
        font-size: 10px;
        color: #000000;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.1px;
        text-align: center;
    }

    .ms-app-ser-tecnhology-div {
        width: 333px;
        height: 214px;
        margin: 45px auto 0px auto;
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-template-rows: 2fr 2fr 2fr;
        /* justify-content: ; */
        /* border: 1px solid #000; */
    }

    .tech-category {
        width: 150px;
        height: 50px;
        background: #000000;
        margin: 0px auto 0px auto;
        display: flex;
        /* cursor: pointer; */
    }

    .tech-category p {
        width: auto;
        height: 30px;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: bold;
        line-height: 30px;
        letter-spacing: 0.24px;
        margin: auto;
    }

    .active-tech-category {
        background: #FFFFFF;
    }

    .active-tech-category p {
        color: #000000;
    }


    .ms-app-ser-technology-techskills {
        width: 333px;
        height: 313px;
        background: rgb(0, 0, 0, 0.3);
        margin: 40px auto 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .skills-list {
        width: 200px;
        height: 275px !important;
        margin: 0px auto 0px auto !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .tech-skill {
        width: 200px;
        height: 50px;
        background: #FFFFFF;
        display: flex;
        margin: 0px auto 25px auto !important;
    }

    .tech-skill:last-child {
        margin: 0px auto 0px auto !important;
    }

    .tech-skill p {
        color: #000;
        width: auto;
        height: auto;
        margin: auto 10px auto 10px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.2px;
        text-align: center;
    }

    .tech-skill img {
        width: auto;
        height: 20px;
        margin: 15px 10px 0px 0px;
    }


    
    .ms-app-ser-section4-container {
        width: 100%;
        height: 796px;
        /* display: flex; */
        margin: 0px auto 0px auto;
    }

    .ms-app-ser-section4-image {
        width: 100%;
        height: 796px;
        /* position: relative; */
    }

    .ms-app-ser-section4-image img {
        width: 430px;
        height: 796px;
        object-fit: fill;
    }

    .ms-app-ser-section4-content-layer {
        width: 100% !important;
        height: 693px !important;
        margin: 0px auto;
        position: absolute;
        z-index: 1;
        display: flex;
        

        /* border: 1px solid #FFFFFF; */
    }
    .ms-app-ser-section4-content {
        width: 360px;
        height: 693px ;
        margin: 60px auto 0px auto !important;        
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        /* border: 1px solid #FFFFFF; */
    }
    .contact-us-form-container {
        width: 330px !important;
        height: 499px !important;
        margin: 40px auto 40px auto !important;
        display: flex;
    }

    .contact-us-form-container {
        margin: 0px auto 0px auto;
        background: rgb(255, 255, 255, 0.4);
        height: fit-content;
    }

    .ms-app-ser-section4-right-div {
        width: 360px;
        height: 162px;
        margin: 30px auto 0px auto !important;
        /* border: 1px solid #FFFFFF; */
    }

    .ms-app-ser-section4-right-div h3 {
        width: 330px;
        height: 32px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.24px;
        text-align: left;
        margin: 0px auto 0px auto;
        color: #FFFFFF;
        text-align: center;
    }

    .ms-app-ser-section4-right-div p {
        width: 320px;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.1px;
        text-align: left;
        margin: 20px auto 0px auto;
        color: #FFFFFF;
        text-align: left;
    }
}