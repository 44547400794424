.blog2-main-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .blog2-section1-container {
    width: 1080px;
    height: 710px;
    margin: 70px auto 0px auto;
    /* border: 1px solid #000; */
  }
  .blog2-section1-image-container {
    width: 1080px;
    height: 480px;
    margin: 0px auto 0px auto;
  }
  .blog2-section1-image-container img {
    width: 1080px;
    height: 480px;
    object-fit: fill;
    margin: 0px auto 0px auto;
  }
  .blog2-section1-container h1 {
    width: 100%;
    height: 48px;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.32px;
    color: #000000;
    font-weight: 600;
    text-align: center;
    margin: 25px 0px 0px 0px;
  }
  .blog2-section1-container p {
    width: 800px;
    height: auto;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.18px;
    color: #000000;
    font-weight: 400;
    text-align: center;
    margin: 20px auto 0px auto;
  }

  
.blog2-section2-container {
    width: 1311px;
    height: auto;
    margin: 45px auto 50px auto;
    /* border: 1px solid #000; */
    position: relative;
    display: flex;
  }
  
  .blog2-left-sidebar {
    width: 250px;
    height: 265px;
    margin: 20px auto 0px auto;
    /* display: flex; */
    position: sticky;
    top: 20px;
    background: #f0f0f0;
  }
  
  .blog2-left-sidebar ul {
    height: 165px;
    width: 201px;
    /* border: 1px solid #000; */
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    padding: 0px;
  }
  
  .blog2-left-sidebar li {
    text-align: left;
    height: auto;
    display: flex;
    margin: 15px auto 0px auto;
    width: 201px;
    padding: 0px;
  }
  .blog2-left-sidebar li > a {
    text-decoration: none;
    height: auto;
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 600;
    width: 100%;
    margin: 0px auto;
  }
  .blog2-left-sidebar li > a:hover {
    color: #000000;
    font-weight: 600;
    text-decoration: underline;
  }
  .blog2-left-sidebar li.active {
    border-left: 7px solid #0500ff;
    background-color: #ffffff;
    padding-left: 5px;
    /* margin-bottom: 10px; */
  }
  .blog2-section2-description-content {
    width: 640px;
    height: auto;
    margin: 0px auto;
    /* border: 1px solid #000; */
  }
  
.blog2-right-sidebar {
    width: 320px;
    height: 874px;
    margin: 0px auto;
    background: #f1eef9;
    position: sticky;
    top: 20px;
  }
  .blog2-section2-text1-container {
    width: 600px;
    height: auto;
    margin: 0px;
    /* display: flex; */
  }
  .blog2-section2-text1-container h2 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 24px;
    letter-spacing: 0.24px;
    line-height: 32px;
    font-weight: bold;
  }
  .blog2-section2-text1-container p {
    width: 580px;
    height: auto;
    margin: 20px 0px 0px 20px;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
  }


  
.blog2-section2-text2-container {
    width: 600px;
    height: auto;
    margin: 0px;
    /* display: flex; */
  }
  .blog2-section2-text2-container h2 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 24px;
    letter-spacing: 0.24px;
    line-height: 32px;
    font-weight: bold;
  }
  .blog2-section2-text2-container ol li {
    margin: 20px 0px 0px 0px;
  }
  .blog2-section2-text2-container ol li > h3 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: bold;
    margin: 0px;
  }
  .blog2-section2-text2-container ol > li::marker {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
  }
  .blog2-section2-text2-container p {
    width: 600px;
    height: auto;
    margin: 10px 0px 0px 0px;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
  }
  .blog2-section2-text2-container p > ul li {
    width: 540px;
    height: auto;
    margin: 0px;
    text-align: left;
    /* border: 1px solid #000; */
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
    list-style-type: disc;
  }

  .blog2-section2-text3-container {
    width: 600px;
    height: auto;
    margin: 0px;
    /* display: flex; */
  }
  .blog2-section2-text3-container h2 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 24px;
    letter-spacing: 0.24px;
    line-height: 32px;
    font-weight: bold;
  }
  
  .blog2-section2-text3-container ol li {
    margin: 5px 0px 0px 0px;
  }
  .blog2-section2-text3-container ol li > h3 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: bold;
    margin: 0px;
  }
  .blog2-section2-text3-container ol > li::marker {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
  }
  .blog2-section2-text3-container p {
    width: 600px;
    height: auto;
    margin: 0px 0px 0px 0px;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
  }
  .blog2-section2-text3-container p > ul li {
    width: 540px;
    height: auto;
    margin: 0px;
    text-align: left;
    /* border: 1px solid #000; */
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
    list-style-type: disc;
  }
  

  .blog2-section2-text4-container {
    width: 600px;
    height: auto;
    margin: 0px;
    /* display: flex; */
  }
  .blog2-section2-text4-container h2 {
    width: 600px;
    height: auto;
    text-align: left;
    color: #000000;
    font-size: 24px;
    letter-spacing: 0.24px;
    line-height: 32px;
    font-weight: bold;
  }
  .blog2-section2-text4-container p {
    width: 580px;
    height: auto;
    margin: 20px 0px 0px 20px;
    text-align: left;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
  }
  
  .blog2-right-sidebar-get-in-touch-with-us-container-description {
    width: 280px;
    height: auto;
    margin: 20px auto 0px auto;
  }
  .blog2-right-sidebar-get-in-touch-with-us-container-description h4 {
    width: 280px;
    height: 40px;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.28px;
    color: #000000;
    margin: 0px auto 0px auto;
  }
  .blog2-right-sidebar-get-in-touch-with-us-container-description p {
    width: 280px;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    margin: 20px auto 0px auto;
    line-height: 25px;
    letter-spacing: 0.2px;
  }
  
  .blog2-right-sidebar-get-in-touch-with-us-container-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
    display: flex;
    margin: 20px 0px 0px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }
  .blog2-right-sidebar-get-in-touch-with-us-container-button p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #ffffff;
  }
  
  .blog2-right-sidebar-get-in-touch-with-us-container-button img {
    margin: 0px 0px 0px 10px;
    width: 18px;
    height: auto;
  }
  .blog2-right-sidebar-related-topics-container {
    width: 280px;
    height: auto;
    margin: 20px auto 0px auto;
  }
  .blog2-right-sidebar-related-topics-container p {
    width: 280px;
    height: auto;
    margin: 0px auto 0px auto;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 30px;
    font-style: italic;
  }
  .blog2-right-sidebar-related-topics-routes {
    width: 280px;
    height: auto;
    margin: 0px auto 0px auto;
    font-size: 20px;
    font-weight: 400;
  }
  .blog2-right-sidebar-related-topics-routes {
    width: 280px;
    height: auto;
    margin: 20px auto 20px auto;
    display: flex;
    flex-direction: column;
  }
  
  .blog2-right-sidebar-related-topics-routes p {
    margin: 10px 0px 10px 0px;
    text-align: left;
    text-decoration: none;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 25px;
    cursor: pointer;
    font-style: normal;
  }
  .blog2-right-sidebar-related-topics-routes p:hover {
      text-decoration: underline;
  }

  @media only screen and (max-width: 432px) {
    .blog2-main-container {
      max-width: 430px;
      width: 98.8%;
      height: auto;
      background: #ffffff;
      margin: 0px 0px 0px 0px;
    }
  
    .blog2-section1-container {
      width: 100%;
      height: auto;
      margin: 60px auto 0px auto;
      /* border: 1px solid #000; */
    }
  
    .blog2-section1-image-container {
      width: 340px;
      height: 180px;
      margin: 0px auto 0px auto;
    }
  
    .blog2-section1-image-container img {
      width: 340px;
      height: 180px;
      object-fit: fill;
      margin: 0px auto 0px auto;
    }
  
    .blog2-section1-container h1 {
      width: 300px;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.16px;
      color: #000000;
      font-weight: 600;
      text-align: center;
      margin: 30px auto 0px auto;
    }
  
    .blog2-section1-container p {
      width: 300px;
      height: auto;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.12px;
      color: #000000;
      font-weight: 400;
      text-align: center;
      margin: 20px auto 0px auto;
    }
  
    .blog2-section2-container {
      width: 320px;
      height: auto;
      margin: 30px auto 50px auto;
      /* border: 1px solid #000; */
      position: relative;
      display: flex;
      flex-direction: column;
    }
  
    .blog2-left-sidebar {
      width: 250px;
      height: 300px;
      margin: 0px;
      display: none;
      background: #f0f0f0;
    }
  
    .blog2-section2-description-content {
      width: 300px;
      height: auto;
      margin: 0px auto 0px auto;
      /* border: 1px solid #000; */
    }
  
    .blog2-section2-text1-container {
      width: 300px;
      height: auto;
      margin: 0px;
      /* display: flex; */
    }
  
    .blog2-section2-text1-container h2 {
      width: 300px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      font-weight: bold;
    }
  
    .blog2-section2-text1-container p {
      width: 280px;
      height: auto;
      margin: 20px 0px 0px 10px;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
    }
  
    .blog2-section2-text2-container {
      width: 300px;
      height: auto;
      margin: 0px;
      /* display: flex; */
    }
  
    .blog2-section2-text2-container h2 {
      width: 300px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      font-weight: bold;
    }
  
    .blog2-section2-text2-container ol li {
      margin: 20px 0px 0px -10px;
    }
  
    .blog2-section2-text2-container ol li > h3 {
      width: 280px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: bold;
      margin: 0px;
    }
  
    .blog2-section2-text2-container ol > li::marker {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
    }
  
    .blog2-section2-text2-container p {
      width: 260px;
      height: auto;
      margin: 10px 0px 0px 0px;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
    }
  
    .blog2-section2-text2-container p > ul li {
      width: 240px;
      height: auto;
      margin: 0px 0px 0px -20px;
      text-align: left;
      /* border: 1px solid #000; */
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
      list-style-type: disc;
    }
  
    .blog2-section2-text3-container {
      width: 300px;
      height: auto;
      margin: 0px;
      /* display: flex; */
    }
  
    .blog2-section2-text3-container h2 {
      width: 300px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      font-weight: bold;
    }
  
    .blog2-section2-text3-container ol li {
      margin: 5px 0px 0px -10px;
      padding: 0px 0px 0px 0px;
    }
  
    .blog2-section2-text3-container ol li > h3 {
      width: 300px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: bold;
      margin: 0px;
    }
  
    .blog2-section2-text3-container ol > li::marker {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
    }
    .blog2-section2-text3-container p{
      width: 280px;
      height: auto;
      margin: 0px 0px 0px 10px;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
    }
     .blog2-section2-text3-container p > ul li {
      width: 260px;
      height: auto;
      margin: 0px 0px 0px -40px;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
    }
  
    .blog2-section2-text4-container {
      width: 300px;
      height: auto;
      margin: 0px;
      /* display: flex; */
    }
  
    .blog2-section2-text4-container h2 {
      width: 300px;
      height: auto;
      text-align: left;
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      font-weight: bold;
    }
  
    .blog2-section2-text4-container p {
      width: 280px;
      height: auto;
      margin: 20px 0px 0px 10px;
      text-align: left;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-weight: 400;
    }
    .blog2-right-sidebar {
      width: 320px;
      height: auto;
      margin: 50px auto 0px auto;
      background: #f1eef9;
    }
    .blog2-right-sidebar-get-in-touch-with-us-container-description {
      width: 280px;
      height: auto;
      margin: 20px auto 20px auto;
    }
  
    .blog2-right-sidebar-get-in-touch-with-us-container-description h4 {
      width: 280px;
      height: auto;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.16px;
      color: #000000;
      margin: 0px auto 0px auto;
    }
  
    .blog2-right-sidebar-get-in-touch-with-us-container-description p {
      width: 200px;
      height: auto;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      margin: 15px 0px 0px 0px;
      line-height: 18px;
      letter-spacing: 0.12px;
    }
  
    .blog2-right-sidebar-get-in-touch-with-us-container-button {
      width: 100px;
      height: 30px;
      border-radius: 4px;
      background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
      display: flex;
      margin: 15px 0px 0px 20px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
    }
  
    .blog2-right-sidebar-get-in-touch-with-us-container-button p {
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.12px;
      width: auto;
      height: auto;
      text-align: center;
      margin: auto 0px auto 10px;
      color: #ffffff;
    }
  
    .blog2-right-sidebar-get-in-touch-with-us-container-button img {
      margin: 0px 0px 0px 5px;
      width: 12px;
      height: auto;
    }
  
    .blog2-right-sidebar-related-topics-container {
      width: 280px;
      height: auto;
      margin: 20px auto 20px auto;
    }
  
    .blog2-right-sidebar-related-topics-container p {
      width: 280px;
      height: auto;
      margin: 0px auto 0px auto;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.12px;
      line-height: 18px;
      font-style: italic;
    }
  
    .blog2-right-sidebar-related-topics-routes {
      width: 280px;
      height: auto;
      margin: 20px auto 20px auto;
      display: flex;
      flex-direction: column;
    }
  
    .blog2-right-sidebar-related-topics-routes p {
      margin: 5px 0px 5px 0px;
      text-align: left;
      text-decoration: none;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 18px;
      cursor: pointer;
      font-style: normal;
    }
  }
  