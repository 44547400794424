::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: #fbfbfb;
}

::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: lightgray;
}

.App{
  font-family: Archivo;
  max-width: 100%;
  height: auto;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
}
.app-container{
  width: 1532px;
  height: auto;
  /* background-color: antiquewhite; */
}
.header-container{
  width: 100%;
  height: 72px;
  background: rgb(51, 153, 153,0.2);
}
.navbar-container{
  width: 1352px;
  height: 72px;
  /* border: 1px solid #000000; */
  margin: 0px auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navbar-mobile-container{
  display: none;
}
.mobile-nav{
  display: none;
}
.mobile-nav-menu-div {
  display: none;
}

.navbar-info-container{
  width: auto;
  height: auto;
  display: flex;
}
.crexin-logo{
  width: 60px;
  height: 52px;
  margin: auto 0px auto 0px;
  cursor: pointer;
}
.crexin-logo img{
  width: 60px;
  height: 52px;
}
.navbar{  
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  margin: auto 0px auto 30px;
  width: 900px;
}
.nav-link{
  font-weight: 600;
  color: #000000;
  width: auto;
  height: auto;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 0px 30px;
  cursor: pointer;
}
.nav-link-active{
  color: #339999;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
}
.enquriy-div{  
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 52px;
  margin: auto 0px auto 202px;
  width: 200px;
}
.envelope-icon{
  width: 16px;
  height: 16px;
}
.contact-email{
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  margin: auto 0px auto 10px;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
}

.footer-main-container{
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px; 
  background-color: #D5DADD;
}
.footer-top-container{
  display: flex;
}
.get-in-touch-with-us-container{
  width: 400px;
  height: 220px;
  background-color: #000000;
  border-radius: 16px;
  margin: 38px 0px 0px 90px;
  display: flex;
  flex-direction: column;
}
.get-in-touch-with-us-container-description{
  width: 320px;
  height: auto;
  margin: 20px auto 0px auto;
}
.get-in-touch-with-us-container-description h4{
  width: 320px;
  height: 40px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.28px;
  color: #FFFFFF;
  margin: 0px auto 0px auto;
}
.get-in-touch-with-us-container-description p{
  width: 320px;
  height: auto;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  margin: 15px auto 0px auto;
  line-height: 25px;
  letter-spacing: 0.2px;
}
.get-in-touch-with-us-container-button{
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to right, #F0C632 0% , #FA4848CC 80%);
  display: flex;
  margin: 20px 0px 0px 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border:none;
}
.get-in-touch-with-us-container-button p{
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 10px;    
  color: #FFFFFF;
}

.get-in-touch-with-us-container-button img{
  margin: 0px 0px 0px 10px;
  width: 18px;
  height: auto;
}
.footer-links-container{
  width: 587px;
  height: 296px;

  margin: 38px 0px 0px 222px;
  display: flex;
  
}
.footer-services-div{
  width: 302px;
  height: auto;
  margin: 0px 0px 0px 0px;

}
.footer-services-div h5{
  font-size: 24px;
  font-weight: 600;
  color: #414B4D;
  letter-spacing: 0.24px;
  line-height: auto;
  margin: 0px 0px 0px 0px;
  text-align: left;
}
.footer-services-div p{
  font-size: 16px;
  font-weight: 300;
  color: #414B4D;
  letter-spacing: 0.16px;
  line-height: auto;
  margin: 15px 0px 0px 0px;
  text-align: left;
  cursor: pointer;
}
.footer-company-div{
  width: 165px;
  height: auto;
  margin: 0px 0px 0px 100px;

}
.footer-company-div h5{
  font-size: 24px;
  font-weight: 600;
  color: #414B4D;
  letter-spacing: 0.24px;
  line-height: auto;
  margin: 0px 0px 0px 0px;
  text-align: left;
}
.footer-company-div p{
  font-size: 16px;
  font-weight: 300;
  color: #414B4D;
  letter-spacing: 0.16px;
  line-height: auto;
  margin: 15px 0px 0px 0px;
  text-align: left;
  cursor: pointer;
}
.footer-divider-line{
  width: 1352px;
  height: 1px;
  background-color: #00000033;
  margin: 20px auto 0px auto;
}
.footer-bottom-container{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.company-logo-div{
  width: 224px;
  height: 65px;
  margin: 25px 0px 0px 90px;
  cursor: pointer;
}
.company-logo-div img{
  width: 224px;
  height: 65px;  
   margin: 0px 0px; 
}

.copyright-div{
  width: auto;
  height: auto;
  margin: 0px 0px 0px 92px;
  display: flex;
}
.copyright-div p{
  font-size: 12px;
  font-weight: 300px;
  letter-spacing: 0.12px;
  margin: 0px 5px 0px 5px;
}
.socail-media-tray{
  width: 240px;
  height: 30px;
  /* border: 1px solid #000; */
  margin: 42px 90px 0px 759px;
  display: flex;
  justify-content: space-between;
}
.socail-media-tray img{
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 432px) {
  .app-container{
    max-width: 432px;
    height: auto;
    width: 98.8%;
    overflow-x:hidden ;
  }
  .header-container{
    width: 100%;
    height: 72px !important;
  }
  .navbar-container{
    display: none;
  }
  .navbar-mobile-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    margin: 0px;
  }
  .crexin-logo{
    width: 60px;
    height: 52px;
    margin: auto 0px auto 20px;
  }
  .crexin-logo img{
    width: 60px;
    height: 52px;
  }
  .toggle-button-div{
    display: flex;
    justify-content: end;
    width: 50px;
    height: 50px;
    margin: auto 30px auto 0px; 
  }
  .mobile-nav{
    width: 99%;
    height: auto;
    display: flex;
    justify-content: flex-end;;
    position: relative;
  }
  .mobile-nav-menu-div {
    margin: 0px 20px 0px auto !important;
    position: absolute ;
    display: flex;
    flex-direction: column;
    z-index: 3;
    width: 164px;
    height: auto;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 4px #d0d0d0;
    border-radius: 0px;
    opacity: 1;
  }
  .nav-link{
    width: auto;
    height: 23px;
    text-align: center;
    color: #000000;
    margin: 5px auto 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 12px;
    letter-spacing: 0.12px;
    font-weight: bold !important;
  }
  .nav-link-div{
    display: flex;
    flex-direction: row;
    margin: 20px auto 10px auto;
  }
  .envelope-icon{
    width: 12px !important;
    height: 12px !important;
    margin: auto;
  }
  .contact-email{
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    margin: auto 0px auto 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
  }



/* FOOTER --------------------------------------------------------------------------------- */

.footer-main-container{
  width: 100%;
  height: 415px;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px; 
  background-color: #D5DADD;
}
.footer-top-container{
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 244px;
  margin: 25px auto 0px auto;
}
.get-in-touch-with-us-container{
  width: 300px;
  height: 75px !important;
  background-color: #000000;
  border-radius: 8px;
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: row; 
}
.get-in-touch-with-us-container-description{
  width: 177px;
  height: 53px !important;
  margin: 10px 0px 10px 20px;
}
.get-in-touch-with-us-container-description h4{
  width: 140px;
  height: 21px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  color: #FFFFFF;
  margin: 0px 0px;
}
.get-in-touch-with-us-container-description p{
  width: 177px;
  height: 26px;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  margin: 2px 0px 0px 0px;
  line-height: 13px;
  letter-spacing: 0.1px;
}
.get-in-touch-with-us-container-button{
  width: 60px;
  height: 20px;
  border-radius: 2px;
  background: linear-gradient(to right, #F0C632 0% , #FA4848CC 80%);
  display: flex;
  margin: auto 0px auto 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border:none;
}
.get-in-touch-with-us-container-button p{
  font-weight: bold;
  font-size: 8px;
  letter-spacing: 0.08px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 0px;    
  color: #FFFFFF;
}

.get-in-touch-with-us-container-button img{
  margin: 0px 0px 0px 10px;
  width: 18px;
  display: none;
}
.footer-links-container{
  width: 264px;
  height: 141px;

  margin: 28px auto 0px auto;
  display: flex;
  
}
.footer-services-div{
  width: 153px;
  height: auto;
  margin: 0px 0px 0px 0px;

}
.footer-services-div h5{
  font-size: 12px;
  font-weight: 600;
  color: #414B4D;
  letter-spacing: 0.12px;
  line-height: auto;
  margin: 0px 0px 0px 0px;
  text-align: left;
}
.footer-services-div p{
  font-size: 10px;
  font-weight: 300;
  color: #414B4D;
  letter-spacing: 0.1px;
  line-height: auto;
  margin: 5px 0px 0px 0px;
  text-align: left;
  cursor: pointer;
}
.footer-company-div{
  width: 82px;
  height: auto;
  margin: 0px 0px 0px 30px;

}
.footer-company-div h5{
  font-size: 12px;
  font-weight: 600;
  color: #414B4D;
  letter-spacing: 0.12px;
  line-height: auto;
  margin: 0px 0px 0px 0px;
  text-align: left;
}
.footer-company-div p{
  font-size: 10px;
  font-weight: 300;
  color: #414B4D;
  letter-spacing: 0.1px;
  line-height: auto;
  margin: 5px 0px 0px 0px;
  text-align: left;
  cursor: pointer;
}
.footer-divider-line{
  width: 83%;
  height: 1px;
  background-color: #00000033;
  margin: 30px auto 0px auto;
}
.footer-bottom-container{
  width: 300px;
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px auto 0px auto;
}
.company-logo-div{
  width: 100px;
  height: 30px;
  margin: 0px 0px 0px 0px;
  cursor: pointer;
}
.company-logo-div img{
  width: 100px;
  height: 30px;  
  margin: 0px 0px; 
}

.copyright-div{
  width: 281px;
  height: 31px;
  margin: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.copyright-div p{
  width: auto;
  font-size: 10px;
  font-weight: 300px;
  letter-spacing: 0.1px;
  margin: 0px 5px 0px 5px;
}
.copyright-div p:first-child{
  margin: 0px 5px 0px 0px;
}
.copyright-div img{
  width: 10px;
  height: 10px;
}
.socail-media-tray{
  width: 100%;
  height: 30px;
  /* border: 1px solid #000; */
  margin: -60px 0px 0px 0px;
  display: flex;
  justify-content:end;
  align-items: center;
}
.socail-media-tray img{
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}





  
}