.blog-main-container {
    width: 100%;
    height: auto;
    margin: 0px 0px 0px 0px;
    background: #F7F7F7;
}

.blog-section1-container {
    width: 1000px;
    height: auto;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.blog-section1-content {
    width: 919px;
    height: 400px;
    margin: 70px auto 0px auto;
    background: rgb(214, 235, 235, 0.5);

}

.blog-section1-content-description {
    width: 848px;
    height: 220px;
    margin: 25px auto 0px auto;
    display: flex;
    align-items: center;
}


.blog-title {
    width: 100%;
    height: auto;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.32px;
    color: #000000;
    margin: 40px auto 0px auto;
    cursor: pointer;
    text-align: center;
}

.blog-section1-content-desc-text {
    width: 430px;
    height: 180px;
    margin: 0px 0px 0px 0px;
}

.blog-section1-content-desc-text p {
    width: 100%;
    height: auto;
    font-size: 20px;
    color: #000000;
    text-align: left;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin: 0px 0px 0px 0px;
}

.read-more-text {
    width: auto;
    height: 20px;
    cursor: pointer;
    /* border: 2px solid #000000; */
    display: flex;
    margin: 10px 0px 0px 0px;
}

.read-more-text p {
    width: auto;
    height: auto;
    font-size: 20px;
    color: #6C71FA !important;
    text-align: left;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin: 0px;
}

.blog-section1-content-image {
    width: 373px;
    height: 220px;
    display: flex;
    margin: 0px 0px 0px 45px;
}

.blog-section1-content-image img {
    width: 373px;
    height: 220px;
    object-fit: fill;
}

.blog-section1-grid-container {
    width: 1000px;
    height: 896px;
    margin: 80px auto 90px auto;
    display: grid;
    grid-template-rows: 2fr 2fr;
    grid-template-columns: 2fr 2fr 2fr;
    justify-content: space-between;
    /* border: 1px solid #000; */
    gap: 40px 40px;
}

.grid {
    width: 300px;
    height: 419px;
    background-color: rgb(214, 235, 235, 0.5);
    cursor: pointer;
}

.blog-grid1 img {
    width: 100%;
    height: 165px;
    margin: 0px;
}

.blog-grid2 img {
    width: 100%;
    height: 165px;
    margin: 0px;
}

.blog-grid3 img {
    width: 100%;
    height: 165px;
    margin: 0px;
}


.grid-heading {
    width: 275px;
    height: auto;
    font-size: 20px !important;
    font-weight: bold;

    margin: 10px auto 0px auto;
    line-height: 24px;
}

.blog-grid1 p,
.blog-grid2 p,
.blog-grid3 p {
    width: 275px;
    height: auto;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 400;
    margin: 10px auto 0px auto;
}

.read-more-text-grid {
    width: auto;
    height: 20px;
    cursor: pointer;
    /* border: 2px solid #000000; */
    display: flex;
    align-items: center;
    margin: 10px 0px 0px 12px;
}

.read-more-text-grid p {
    width: auto;
    height: auto;
    font-size: 16px;
    color: #6C71FA;
    text-align: left;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 0px;
}

@media only screen and (max-width: 432px) {
    .blog-main-container {
        max-width: 430px;
        width: 98.8%;
        height: auto;
        background: #FFFFFF;
        margin: 0px 0px 0px 0px;
    }

    .blog-section1-container {
        width: 100%;
        height: auto;
        margin: 25px auto;
        display: flex;
        flex-direction: column;
    }

    .blog-section1-content {
        width: 330px;
        height: 420px;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        margin: 0px auto 0px auto;
        border: none;
        background: rgb(214, 235, 235, 0.5);
        /* border-radius: 8px; */
    }

    .blog-section1-content-description {
        width: 100%;
        height: 388px;
        margin: 20px auto;
        display: flex;
        flex-direction: column-reverse;
    }


    .blog-title {
        width: 300px;
        height: 48px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.16px;
        color: #000000;
        margin: 25px auto 0px auto;
        cursor: pointer;
        text-align: left;
    }

    .blog-section1-content-desc-text {
        width: 300px;
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 10px auto 0px auto;
    }

    .blog-section1-content-desc-text p {
        width: 300px;
        height: auto;
        margin: 0px auto;
        font-size: 12px;
        color: #000000;
        text-align: left;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.12px;
    }

    .read-more-text {
        width: auto;
        height: auto;
        display: flex;
        margin: 10px auto 0px auto;
    }

    .read-more-text p {
        cursor: pointer;
        font-size: 12px;
        color: #2c4e6c;
        text-align: left;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.12px;
        /* text-decoration: underline; */
    }

   


    .blog-section1-content-image {
        width: 300px;
        height: 180px;
        /* border-radius: px; */
        display: flex;
        margin: 0px auto 0px auto;
    }

    .blog-section1-content-image img {
        width: 100%;
        height: 165px;
        margin: auto;
    }

    .blog-section1-grid-container {
        width: 100%;
        height: 100%;
        margin: 25px auto 0px auto;
        display: grid;
        grid-template-rows: 2fr 2fr 2fr;
        grid-template-columns: 2fr;
    }

    .grid {
        width: 300px;
        height: auto;
        background: rgb(214, 235, 235, 0.5);
        margin: 0px auto 10px auto;
    }


    .blog-grid1 img {
        width: 100%;
        height: 165px;
        margin: 0px auto;

    }

    .blog-grid2 img {
        width: 100%;
        height: 165px;

        margin: 0px;
    }

    .blog-grid3 img {
        width: 100%;
        height: 165px;

        margin: 0px;
    }

    .grid-heading {
        width: 280px;
        height: auto;
        font-size: 16px !important;
        font-weight: bold;
        text-align: left;
        margin: 10px auto;
        line-height: 20px;
    }

    .blog-grid1 p,
    .blog-grid2 p,
    .blog-grid3 p {
        width: 280px;
        height: auto;
        font-size: 12px;
        color: #000000;
        line-height: 18px;
        letter-spacing: 0.12px;
        font-weight: 500;
        opacity: 1;
        margin: 10px auto 0px auto;
        text-align: left;
    }

    .read-more-text-grid {
        width: 280px;
        height: auto;
        cursor: pointer;
        /* border: 2px solid #000000; */
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 10px auto 10px auto;
    }

    .read-more-text-grid p {
        width: auto;
        height: auto;
        font-size: 12px;
        color: #427CAC !important;
        text-align: left;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.12px;
        margin: 0px;
    }

   
    .blog-section2-content-bg {
        width: 90%;
        height: 157px;
        border: 2px solid #000000;
        margin: 25px auto 30px auto;
        background: #E5F7EB;
        position: relative;
    }

    .blog-section2-content {
        width: 100%;
        height: 157px;
        border: 2px solid rgb(0, 92, 11, 0.5);
        z-index: 1;
        position: absolute;
        margin: 4px 0px 0px 4px;
    }

    .blog-section2-content-desc-text {
        width: 250px;
        height: 39px;
        margin: 20px auto 0px auto;

        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }

    .blog-section2-content-start-free-trial-div {
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: center;
    }

    .blog-section2-content-start-free-trial {
        width: 125px;
        height: 35px;
        border: 2px solid #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 2px 2px #00000029;
        background: #00521B;
        margin: 43px 0px 0px 0px;
        color: #FFFFFF;
        font-size: 12px;

        cursor: pointer;
        font-weight: 500;

    }

    .blog-section2-content-start-free-trial:hover {
        background: #48588D;
        color: #FFFFFF;
    }

    .blog-go-to-top {
        width: 95%;
        height: 25px;
        display: flex-end;
        margin: 25px 10px 0px 0px;
    }
}