.careers-page-container {
    width: 1532px;
    height: auto;
    overflow: hidden;
    margin: 0px auto;
}

.careers-page-section1-container {
    width: 100%;
    height: 832px;
    margin: 0px auto;
    display: flex;

}

.careers-page-section1-image {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    position: relative;
}

.careers-page-section1-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.careers-page-section1-content-layer {
    width: 1532px;
    height: 832px;
    margin: 0px auto;
    position: absolute;
    z-index: 1;
    background: rgb(36, 104, 142, 0.6);
    display: flex;
}

.careers-page-section1-content {
    width: 100%;
    height: 832px;
    display: flex;
    flex-direction: row;
}

.careers-page-section1-resume-form-div {
    width: 705px;
    height: 440px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin: 224px 0px 0px 90px;
}

.careers-page-section1-resume-form-div p {
    width: 80%;
    height: auto;
    margin: 20px auto 0px auto;
    color: #000000;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 30px;
    text-align: center;
}

.careers-page-section1-resume-form {
    width: 620px;
    height: auto;
    margin: auto auto;
    /* border: 2px solid #000; */
    background: none;
}

.input-row {
    width: 620px;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin: 0px auto 25px auto;
}

.input-row-2 {
    width: 620px;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin: 0px auto 25px auto;
}

.input-resume-row {
    width: 620px;
    height: 100px;
    display: flex;
    flex-direction: column;
    margin: 0px auto 25px auto;
}

.fullname-group,
.experience-group {
    width: 300px;
    height: 50px;
    margin: 0px 10px 0px 0px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 4px;
}

.company-group {
    width: 620px;
    height: 50px;
    margin: 0px 0px 0px 0px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 4px;
}

.formdata-company {
    width: 600px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    font-size: 16px;
}

.resume-group {
    width: 620px;
    height: 80px;
    margin: 0px 0px 0px 0px;
    border: 1px dashed rgb(0, 0, 0, 0.5);
    border-radius: 4px;
}

.formdata-resume {
    width: 600px;
    height: 75px;
    display: none;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    /* padding: 15px 0px 0px 20px; */
    font-size: 16px;
    resize: none;
    font-family: Archivo;
    letter-spacing: 0.16px;
    line-height: 1.5;

}

.resume-group label {
    width: 100%;
    height: 22px;
    margin: 28px auto 0px auto;
    font-size: 16px;
    resize: none;
    font-family: Archivo;
    letter-spacing: 0.16px;
    line-height: 1.5;
    color: #909090;
    text-align: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
}

.resume-group label img {
    width: 20px;
    height: 20px;
    margin: 0px 5px;
}

.formdata-fullname,
.formdata-experience {
    width: 280px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 1.5;


}

.email-group,
.skills-group {
    width: 300px;
    height: 50px;
    margin: 0px 0px 0px 10px;
    border: 1px solid rgb(0, 0, 0, 0.5);
    border-radius: 4px;
}

.formdata-email,
.formdata-skills {
    width: 280px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-size: 16px;
    /* font-weight: "Catamaran"; */
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    letter-spacing: 0.16px;
    line-height: 1.5;
}

.submit-button-div {
    width: 120px;
    height: 40px;
    margin: 25px auto 0px auto;
}

.careers-page-form-submit-button {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: none;
    background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
    display: flex;
    margin: 0px auto 0px auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.careers-page-form-submit-button p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 0px;
    color: #FFFFFF;
}

.careers-page-section1-side-description {
    width: 550px;
    height: 95px;
    margin: 328px 90px 0px 110px;
    display: flex;
    flex-direction: column;
}

.careers-page-section1-side-description h4 {
    width: 100%;
    height: 40px;
    font-size: 36px;
    font-weight: bold;
    /* letter-spacing: 0.3px; */
    line-height: 40px;
    color: #FFFFFF;
    margin: 0px 0px;

}

.careers-page-section1-side-description p {
    width: 100%;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
}

.careers-page-section2-container {
    width: 100%;
    height: 675px;
    background: rgb(36, 104, 142, 0.1);
    display: flex;
    flex-direction: column;
}

.careers-page-section2-title-container {
    width: 470px;
    height: 50px;
    margin: 70px auto 0px auto;
}

.careers-page-section2-title-container h3 {
    font-size: 36px;
    height: auto;
    width: auto;
    margin: 0px;
    font-weight: bold;
    letter-spacing: 0.36px;
    line-height: 48px;
    text-align: center;
    color: #000000;
}

.careers-page-section2-title-container h3 span {
    color: #000000;
}

.careers-page-section2-description {
    width: 750px;
    height: 105px;
    margin: 30px 0px 0px 90px;
}

.careers-page-section2-description p {
    width: 740px;
    height: auto;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #000000;
    font-weight: 400;
}

.careers-page-section2-recruitment-process-container {
    width: 1316px;
    height: 320px;
    margin: 57px auto 0px auto;
    /* border: 1px solid #000; */
}

.careers-page-section2-recruitment-process-steps {
    width: 1316px;
    height: 50px;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.recruitment-step {
    width: 224px;
    height: 50px;
    margin: 0px 0px 0px 0px;
    display: inline-flex;
}

.recruitment-step-id {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid rgb(51, 153, 153, 0.2);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 500;
    z-index: 1;
    margin: 9px -15px 0px 0px;
}

.recruitment-step-id p {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(51, 153, 153, 0.9);
    color: rgba(255, 255, 255);
    text-align: center;
}

.active-step-id p {
    background-color: rgba(255, 255, 255);
    color: #339999;

}

.recruitment-step-name {
    width: 212px;
    height: 50px;
    margin: 0px 0px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #339999;
    background: #FFFFFF;
    border: 1px solid #339999;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 500;
}

.active-step-name {
    color: #FFFFFF;
    background: rgb(51, 153, 153, 0.9);
    border: none;
}

.recruitment-process-step-description {
    width: 1280px;
    height: 178px;
    background-color: #ffffff;
    margin: 66px auto 0px auto;
    display: flex;
    align-items: center;
}

.recruitment-process-step-description p {
    width: 1200px;
    height: auto;
    margin: auto 40px auto 40px;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 30px;
    color: #000000;

}

.careers-page-section3-container {
    width: 100%;
    height: 856px;
    display: flex;

}

.careers-page-section3-content {
    width: 1532px;
    height: 856px;
    background-color: rgb(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.careers-page-section3-content h3 {
    width: 515px;
    height: 48px;
    margin: 70px 0px 0px 90px;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.36px;
    color: #FFFFFF;
}

.careers-page-section3-vacancies-grid {
    width: auto;
    height: auto;
    margin: 70px auto 0px auto;
    border: 1px solid #000;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
}

.careers-page-section3-vacancy-grid-item {
    width: 660px;
    height: 150px;
    background: #FFFFFF;
    display: flex;
}

.careers-page-section3-vacancy-grid-description {
    width: 408px;
    height: 115px;
    margin: 20px 0px 0px 40px;
    /* border: 1px solid #000; */

}

.careers-page-section3-vacancy-grid-description p {
    width: auto;
    height: auto;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    text-align: left;
    margin: 0px;
    color: #000000;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 500;
}

.careers-page-section3-vacancy-grid-description span {
    width: 15px;
    height: 20px;
    margin: -5px 5px 0px 5px;
}

.careers-page-section3-vacancy-grid-description h3 {
    width: auto;
    height: auto;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: 0.36px;
    text-align: left;
    margin: 0px 0px 5px 0px;
    color: #000000;
    font-weight: 500;
}

.careers-page-section3-vacancy-grid-apply-now-button {
    width: 150px;
    height: 50px;
    margin: 50px 43px 0px auto;
    background: #339999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.careers-page-section3-vacancy-grid-apply-now-button:hover {
    outline: 4px solid rgb(51, 153, 153, 0.3);
}


@media only screen and (max-width: 432px) {
    .careers-page-container {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 0px auto;
    }

    .careers-page-section1-container {
        width: 100%;
        height: 751px;
        margin: 0px auto;
        display: flex;
    }

    .careers-page-section1-image {
        width: 430px;
        height: 751px;
        margin: 0px auto;
        display: flex;
        position: relative;
    }

    .careers-page-section1-image img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }

    .careers-page-section1-content-layer {
        width: 100%;
        height: 751px;
        margin: 0px auto;
        position: absolute;
        z-index: 1;
        background: rgb(36, 104, 142, 0.6);
        display: flex;
    }

    .careers-page-section1-content {
        width: 100%;
        height: 751px;
        display: flex;
        margin: 0px auto 0px auto;
        padding: 0px;
        justify-content: start;
        flex-direction: column-reverse;
    }

    .careers-page-section1-side-description {
        width: 320px;
        height: auto;
        margin: 60px auto 0px auto !important;
        display: flex;
        flex-direction: column;
    }

    .careers-page-section1-side-description h4 {
        width: 100%;
        height: auto;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.24px;
        line-height: 32px;
        color: #FFFFFF;
        margin: 0px 0px;
        text-align: center;

    }

    .careers-page-section1-side-description p {
        width: 100%;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 15px;
        color: #FFFFFF;
        text-align: center;
    }

    .careers-page-section1-resume-form-div {
        width: 340px;
        height: 466px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px auto 0px auto;
    }

    .careers-page-section1-resume-form-div p {
        width: 80%;
        height: auto;
        margin: 0px auto 0px auto;
        color: #000000;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 15px;
        text-align: center;
    }

    .careers-page-section1-resume-form {
        width: 250px;
        height: 369px;
        margin: 15px auto 0px auto;
        /* border: 2px solid #000; */
        background: none;
    }

    .input-row {
        width: 250px;
        height: 100px;
        display: flex;
        flex-direction: column;
        margin: 0px auto 0px auto;
    }

    .input-row-2 {
        width: 250px;
        height: 100px;
        display: flex;
        flex-direction: column;
        margin: 0px auto 0px auto;
    }

    .input-resume-row {
        width: 250px;
        height: 100px;
        display: flex;
        flex-direction: column;
        margin: 0px auto 25px auto;
    }

    .fullname-group,
    .experience-group {
        width: 250px;
        height: 40px;
        margin: 20px 0px 0px 0px;
        border: 1px solid rgb(0, 0, 0, 0.5);
        border-radius: 2px;
    }

    .resume-group {
        width: 250px;
        height: 80px;
        margin: 20px 0px 0px 0px;
        border: 1px dashed rgb(0, 0, 0, 0.5);
        border-radius: 4px;
    }

    .formdata-resume {
        width: 250px;
        height: 75px;
        display: none;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        /* padding: 15px 0px 0px 20px; */
        font-size: 10px;
        resize: none;
        font-family: Archivo;
        letter-spacing: 0.1px;
        line-height: 1.5;

    }

    .resume-group label {
        width: 100%;
        height: 22px;
        margin: 30px auto 0px auto;
        font-size: 10px;
        resize: none;
        font-family: Archivo;
        letter-spacing: 0.1px;
        line-height: 1.5;
        color: #909090;
        text-align: center;
        display: flex;
        cursor: pointer;
        justify-content: center;
    }

    .resume-group label img {
        width: 15px;
        height: 15px;
        margin: 0px 5px;
    }

    .formdata-fullname,
    .formdata-experience {
        width: 230px;
        height: 38px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 1.5;


    }

    .email-group,
    .skills-group {
        width: 250px;
        height: 40px;
        margin: 10px 0px 0px 0px;
        border: 1px solid rgb(0, 0, 0, 0.5);
        border-radius: 4px;
    }

    .formdata-email,
    .formdata-skills {
        width: 230px;
        height: 38px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        font-size: 10px;
        /* font-weight: "Catamaran"; */
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        letter-spacing: 0.1px;
        line-height: 1.5;
    }

    .submit-button-div {
        width: 90px;
        height: 30px;
        margin: 20px auto 0px auto;
    }

    .careers-page-form-submit-button {
        width: 90px;
        height: 30px;
        border-radius: 3px;
        border: none;
        background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
        display: flex;
        margin: 0px auto 0px auto;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .careers-page-form-submit-button p {
        font-weight: bold;
        font-size: 10px;
        letter-spacing: 0.2px;
        width: auto;
        height: auto;
        text-align: center;
        margin: auto 0px auto 0px;
        color: #FFFFFF;
    }


    .careers-page-section2-container {
        width: 100%;
        height: 654px;
        background: rgb(36, 104, 142, 0.1);
        display: flex;
        flex-direction: column;
    }

    .careers-page-section2-title-container {
        width: 293px;
        height: 32px;
        margin: 50px auto 0px auto;
    }

    .careers-page-section2-title-container h3 {
        font-size: 24px;
        height: auto;
        width: 100%;
        margin: 0px;
        font-weight: bold;
        letter-spacing: 0.24px;
        line-height: 32px;
        text-align: center;
        color: #000000;
    }

    .careers-page-section2-title-container h3 span {
        color: #000000;
    }

    .careers-page-section2-description {
        width: 280px;
        height: 75px;
        margin: 30px auto 0px auto;
        /* display: flex; */
    }

    .careers-page-section2-description p {
        width: 280px;
        height: auto;
        text-align: left;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #000000;
        font-weight: 400;
        margin: 0px auto 10px auto !important;
    }

    .careers-page-section2-recruitment-process-container {
        width: 324px;
        height: 407px;
        margin: 30px auto 0px auto;
    }

    .careers-page-section2-recruitment-process-steps {
        width: 324px;
        height: 189px;
        margin: 0px auto 0px auto;
        display: grid;
        grid-template-rows: 2fr 2fr 2fr;
        grid-template-columns: 2fr 2fr;
        justify-content: space-between;
        /* align-items: center; */
    }

    .recruitment-step {
        width: 158px;
        height: 50px;
        margin: 0px 0px 0px 0px;
        display: inline-flex;
    }

    .recruitment-step-id {
        width: 24px !important;
        height: 24px !important;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 4px solid rgb(51, 153, 153, 0.2);
        font-size: 10px;
        /* line-height: 15px; */
        letter-spacing: 0.1px;
        font-weight: 500;
        z-index: 1;
        margin: 5px -15px 0px 0px;
    }

    .recruitment-step-id p {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgb(51, 153, 153, 0.9);
        color: rgba(255, 255, 255);
        text-align: center;
    }
    .active-step-id p {
        background-color: rgba(255, 255, 255);
        color: #339999;
    
    }

    .recruitment-step-name {
        width: 150px;
        height: 40px;
        margin: 0px 0px;
        border-radius: 3px;
        text-align: left;
        display: flex;
        margin: 0px 0px 0px 0px;
        color: #339999;
        background: #FFFFFF;
        border: 1px solid #339999;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        font-weight: 500;
    }
    
.active-step-name {
    color: #FFFFFF;
    background: rgb(51, 153, 153, 0.9);
    border: none;
}
.recruitment-process-step-description {
    width: 324px;
    height: 140px;
    background-color: #ffffff;
    margin: 20px auto 20px auto;
    display: flex;
    align-items: center;
}

.recruitment-process-step-description p {
    width: 280px;
    height: auto;
    margin: auto 20px auto 20px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    color: #000000;

}
}