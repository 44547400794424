.services-page-container {
  width: 1532px;
  height: auto;
  overflow: hidden;
}

.services-page-section1-container {
  width: 1532px;
  height: 844px;
  /* position: relative; */
  overflow: hidden;
  display: flex;
}

.services-page-section1-background-image {
  width: 100%;
  height: 844px;
  margin: 0px;
  position: relative;
}

.services-page-section1-background-image img {
  width: 100%;
  height: 100%;
  margin: 0px;
  object-fit: fill;
}

.services-page-background-layer {
  width: 1532px;
  height: 844px;
  background-color: rgb(0, 0, 0, 0.51);
  /* margin: 0px; */
  z-index: 2;
  position: absolute;
  /* display: flex;
    flex-direction: row; */
  overflow: hidden;
}

.services-page-section1-description {
  width: 558px;
  height: 287px;
  margin: 262px 0px 0px 90px;
  /* border: 1px solid #FFFFFF; */
}

.services-page-section1-description h2 {
  width: 523px;
  height: 103px;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 0.42px;
  line-height: 54px;
  margin: 0px;
  align-items: left;
  color: #ffffff;
}

.services-page-section1-description p {
  width: 558px;
  height: 150px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 30px;
  margin: 30px 0px 0px 0px;
  align-items: left;
  color: #ffffff;
}

.services-page-section2-container {
  width: 100%;
  height: 1788px;
  margin: 0px auto;
  background: linear-gradient(
    to left,
    rgb(119, 118, 165, 0.5) 0%,
    #fafaff 100%
  );
  display: flex;
  flex-direction: column;
}

.services-page-section2-services-container-grid {
  width: 1352px;
  height: 1510px;
  margin: 50px auto 0px auto;
  display: grid;
  grid-template-rows: 2fr 2fr 2fr 2fr;
  grid-template-columns: 2fr 2fr;
  row-gap: 40px;
  /* column-gap: 20px; */
}

.services-page-section2-services-grid {
  width: 627px;
  height: 350px;
  margin: 0px auto 0px auto;
  border: 1px solid rgb(201, 201, 201, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.services-page-section2-service-grid-content-div {
  width: 576px;
  margin: 25px 11px 15px 25px;
  /* border: 1px solid #000000; */
  display: flex;
  justify-content: space-between;
  height: 235px;
}

.services-page-section2-service-grid-description {
  width: 330px;
  height: 235px;
  margin: 0px -60px 0px 0px;
  display: flex;
  background: #d9d9d980;
  clip-path: polygon(0% 100%, 78% 100%, 100% 0%, 0% 0%);
  flex-direction: column;
}

.services-page-section2-services-grid h3 {
  width: auto;
  height: 30px;
  color: #000000;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin: 30px auto 0px auto;
}

.services-page-section2-service-grid-description p {
  width: 234px;
  height: auto;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.18px;
  line-height: 24px;
  margin: 10px 0px 0px 20px;
}

.services-page-section2-service-grid-image {
  height: 235px;
  width: 330px;
  /* overflow: hidden; */
  margin: 0px 0px 0px 0px;
  clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.services-page-section2-service-grid-image img {
  height: 100%;
  width: 330px;
  margin: 0px;
  /* border-radius: 5px; */
}

.services-page-explore-now-container-button {
  width: 176px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
  display: flex;
  margin: 10px 0px 0px 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.services-page-explore-now-container-button p {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 10px;
  color: #000000;
}

.services-page-explore-now-container-button img {
  margin: 0px 0px 0px 10px;
  width: 18px;
  height: auto;
}

.services-page-section3-container {
  width: 100%;
  height: 1565px;
  background-color: #ffffff;
  /* border: 1px solid #000000; */
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
}

.services-page-technical-process-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 1300px;
  height: 1300px;
  margin: 50px auto 0px auto;
}

.services-page-technical-process-grid-item {
  width: 400px;
  height: 400px;
  padding: 0px;
  background-color: rgb(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: center; */
}

.services-page-technical-process-grid-item:hover {
  background-color: rgb(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.services-page-technical-process-grid-id {
  width: auto;
  height: auto;
  font-size: 42px;
  margin: 15px 0px 0px 15px;
}
.services-page-technical-process-grid-icon {
  width: 100px;
  height: 100px;
  margin: 40px auto 0px auto;
}

.services-page-technical-process-grid-item p {
  width: 310px;
  height: auto;
  font-size: 20px;
  line-height: 1.5;
  color: #000000;
  margin: 30px auto 0px auto;
  text-align: center;
}
.services-page-technical-process-grid-item h3 {
  width: auto;
  height: auto;
  text-align: center;
  font-size: 28px;
  line-height: 1.5;
  color: #000000;
  margin: 20px auto 0px auto;
  font-weight: bold;
}
.services-page-section4-container {
  width: 100%;
  height: 1046px;
  display: flex;
  margin: 0px auto 0px auto;
  flex-direction: column;
  background: rgb(51, 153, 153, 0.33);
}
.services-page-section4-content {
  width: 1200px;
  height: 722px;
  margin: 80px auto 0px auto;
  /* background-color: #FFFFFF; */
  display: flex;
}
.services-page-section4-faqs-div {
  width: 772px;
  height: 722px;
  margin: 0px;
  /* border: 1px solid #000000; */
}
.services-page-section4-faqs-image {
  width: 429px;
  height: 576px;
  margin: auto 0px;
}
.services-page-section4-faqs-image img {
  width: 429px;
  height: 576px;
  margin: 0px 0px;
  object-fit: cover;
}
.faq-div {
  width: 772px;
  height: 60px;
  margin: 0px auto 22px auto;
  background: #f0f0f0;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  cursor: pointer;
}
.active-faq-div {
  width: 772px;
  height: auto;
  margin: 0px 0px 22px 0px;
  background: #f0f0f0;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  padding-top: 10px;
}

.active-faq-div > .question-div {
  margin: 0px 0px 0px 0px;
}

.question-div {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0px auto 0px auto;
}

.question-div p {
  width: 665px;
  height: auto;
  text-align: left;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 1;
  margin: auto 0px auto 20px;
}

.question-div img {
  width: 20px;
  height: 10px;
  cursor: pointer;
  margin: auto 0px auto 30px;
}

.active-faq-div > .answer-div {
  width: 652px;
  height: auto;
  margin: 10px 0px 0px 0px;
  /* background-color: #0425af; */
  /* border: 1px solid #000; */
}

.active-faq-div > .answer-div p {
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 1.5;
  color: rgb(0, 0, 0, 0.8);
  opacity: 1;
  margin: 0px 0px 0px 20px;
  padding-bottom: 10px;
}
.services-page-section5-container {
  width: 100%;
  height: 966px;
  /* background: #F0C632; */
  display: flex;
}
.services-page-section5-bg-image {
  width: 100%;
  height: 966px;
  margin: 0px;
  position: relative;
}
.services-page-section5-bg-image img {
  width: 100%;
  height: 966px;
}
.services-page-section5-content {
  width: 1532px;
  height: 865px;
  position: absolute;
  z-index: 2;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 432px) {
  .services-page-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .services-page-section1-container {
    width: 100%;
    height: 563px;
    /* position: relative; */
    overflow: hidden;
    display: flex;
  }

  .services-page-section1-background-image {
    width: 100%;
    height: 563px;
    position: relative;
    display: flex;
  }

  .services-page-section1-background-image img {
    width: 100%;
    height: 563px;
    margin: 0px;
  }
  .services-page-background-layer {
    width: 100%;
    height: 563px;
    background-color: rgb(0, 0, 0, 0.51);
    z-index: 2;
    position: absolute;
    overflow: hidden;
  }
  .services-page-section1-description {
    width: 296px;
    height: 153px;
    margin: 353px auto 0px auto;
  }

  .services-page-section1-description h2 {
    width: 262px;
    height: 58px;
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0.21px;
    line-height: 27px;
    margin: 0px;
    align-items: left;
    color: #ffffff;
  }

  .services-page-section1-description p {
    width: 100%;
    height: 75px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    margin: 20px 0px 0px 0px;
    align-items: left;
    color: #ffffff;
  }

  .services-page-section2-container {
    width: 100%;
    height: 863px;
    margin: 0px auto 0px auto;
    background: linear-gradient(
      to left,
      rgb(119, 118, 165, 0.5) 0%,
      #fafaff 100%
    );
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .services-page-section2-services-container-grid {
    width: 340px;
    height: auto;
    margin: 50px auto 0px auto;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: 2fr 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr ;
    row-gap: 10px;
    column-gap: 10px;
  }

  .services-page-section2-services-grid {
    width: 160px;
    height: 170px;
    margin: 0px auto 0px auto;
    border: 1px solid rgb(201, 201, 201, 0.5);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  
.services-page-section2-service-grid-content-div {
    width: 135px;
    margin: 0px 0px 0px 10px;
    /* border: 1px solid #000000; */
    display: flex;
    justify-content: space-between;
    height: auto;
  }
  
  .services-page-section2-service-grid-description {
    width: 135px;
    height: auto;
    margin: 10px 0px 0px 0px;
    display: flex;
    background: none;
    clip-path: none;
    flex-direction: column;
  }
  
.services-page-section2-services-grid h3 {
    width: 140px;
    height: auto;
    color: #000000;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.12px;
    margin: 10px 0px 0px 10px;
  }
  
  .services-page-section2-service-grid-description p {
    width: 135px;
    height: auto;
    color: #000000;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 12px;
    margin: 0px 0px 0px 0px;
  }
  
  .services-page-section2-service-grid-image {
    height: 225px;
    width: 330px;
    display: none;
    /* overflow: hidden; */
    margin: 0px 0px 0px 0px;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
  
.services-page-explore-now-container-button {
    width: 100px;
    height: 25px;
    border-radius: 2px;
    background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
    display: flex;
    margin: 7px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }
  
  .services-page-explore-now-container-button p {
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.1px;
    width: 70px;
    height: 12px;
    text-align: center;
    margin: 7.5px 0px;
    color: #000000;
  }
  
  .services-page-explore-now-container-button img {
    margin: 0px 0px 0px 5px;
    width: 8px;
    height: auto;
  }

  .services-page-section3-container {
    width: 100%;
    height: 1090px;
    background-color: #E0E0E0;
    /* border: 1px solid #000000; */
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
  }
  
  .services-page-technical-process-grid-container {
    display: none;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 340px;
    height: 970px;
    margin: 30px auto 0px auto;
  }
  .services-page-technical-process-grid-container-mob-responsive{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 340px;
    height: 970px;
    margin: 30px auto 0px auto;
  }
  .services-page-technical-process-grid-item-mob-responsive {
    width: 340px;
    height: 90px;
    padding: 0px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: start;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    /* align-items: center; */
  }
  .services-page-technical-process-grid-item {
    background-color:none;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.services-page-technical-process-grid-id {
    width: 100%;
    height: 24px;    
    display: flex;
    justify-content: end;
    /* position: fixed; */
    z-index: 2;
    margin: 10px 0px 0px 0px;
  }
.services-page-technical-process-grid-id h2{
    font-size: 16px;
    line-height: 24px;
    width: auto;
    height: auto;
    margin: 0px 15px 0px 0px;
}
.services-page-technical-process-grid-content{
    width: 300px;
    height: 72px;
    margin: -25px auto 0px auto;
    display: flex;
    flex-direction: row;
}
.services-page-technical-process-grid-icon {
    width: 50px;
    height: 50px;
    margin: auto 0px auto 0px;
    display: flex;
  }
  .services-page-technical-process-grid-description{
    width: 232px;
    height: 100%;
    margin: 5px 0px auto 8px;
  }
  .services-page-technical-process-grid-description h3{
    width: auto;
    height: auto;
    text-align: center;
    font-size: 14px;
    line-height: auto;
    color: #000000;
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    text-align: left;
  }
  .services-page-technical-process-grid-description p {
    width: 100%;
    height: auto;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    margin: 3px 0px 0px 0px;
    text-align: left;
  }

  .services-page-section4-container {
    width: 100%;
    height: 878px;
    display: flex;
    margin: 0px auto 0px auto;
    flex-direction: column;
    background: rgb(51, 153, 153, 0.33);
  }
  .services-page-section4-content {
    width: 297px;
    height: 725px;
    margin: 30px auto 0px auto;
    /* background-color: #FFFFFF; */
    display: flex;
    flex-direction: column-reverse;
  }
  .services-page-section4-faqs-div {
    width: 100%;
    height: 407px;
    margin: 30px auto;
    /* border: 1px solid #000000; */
  }
  .services-page-section4-faqs-image {
    width: 217.5px;
    height: 288px;
    margin: 0px auto;
  }
  .services-page-section4-faqs-image img {
    width: 217.5px;
    height: 288px;
    margin: 0px 0px;
    object-fit: cover;
}

.faq-div {
    width: 297px;
    height: 30px;
    margin: 0px auto 15px auto;
    background: #f0f0f0;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* cursor: pointer; */
  }
  .active-faq-div {
    width: 297px;
    height: auto;
    margin: 0px auto 22px auto;
    background: #f0f0f0;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding-top: 10px;
  }
  .question-div p {
    width: 252px;
    height: auto;
    text-align: left;
    line-height: 12px;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.1px;
    color: #000000;
    opacity: 1;
    margin: auto 0px auto 20px;
  }


  .question-div img {
    width: 10px;
    height: 5px;
    /* cursor: pointer; */
    margin: auto 10px auto 0px;
  }
  
.active-faq-div > .answer-div {
    width: 297px;
    height: auto;
    margin: 10px 0px 0px 0px;
    /* background-color: #0425af; */
    /* border: 1px solid #000; */
  }
  .active-faq-div > .answer-div p {
    text-align: left;
    width: 252px;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.1px;
    line-height: 12px;
    color: rgb(0, 0, 0, 0.8);
    opacity: 1;
    margin: 0px 0px 0px 20px;
    padding-bottom: 10px;
  }

  .services-page-section5-container {
    width: 100%;
    height: 800px;
    /* background: #F0C632; */
    display: flex;
  }
  .services-page-section5-bg-image {
    width: 100%;
    height:800px;
    margin: 0px;
    position: relative;
  }
  .services-page-section5-bg-image img {
    width: 100%;
    height: 800px;
  }
  .services-page-section5-content {
    width: 100%;
    height: 620px;
    position: absolute;
    z-index: 2;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
  }
}
