.homepage-container {
    width: 100%;
    height: auto;
}

.homepage-section1-container {
    width: 100%;
    height: 680px;
    position: relative;
    overflow: hidden;
}

.homepage-section1-background-image {
    width: 1532px;
    height: 680px;
    z-index: 1;
    position: absolute;
}

.homepage-section1-background-image img {
    width: 1532px;
    height: 680px;
    object-fit: cover;
}

.homepage-background-layer {
    width: 1532px;
    height: 680px;
    /* background-color: rgb(0, 0, 0, 0.6); */
    margin: 0px;
    z-index: 2;
    position: absolute;
}

.section1-text-container {
    width: auto;
    height: 180px;
    margin: 215px auto;

}

.section1-text-container h2 {
    width: auto;
    font-size: 48px;
    line-height: auto;
    letter-spacing: 0.48px;
    font-weight: bold;
    text-align: center;
    margin: 0px auto;
    color: #FFFCF3;
}

.section1-text-container p {
    width: 650px;
    height: 110px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.2px;
    font-weight: normal;
    text-align: center;
    margin: 20px auto;
    color: #FFFCF3;

}

.homepage-section2-container {
    width: 100%;
    height: 1171px;
    background-color: #F0F0F0;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.section2-title-container {
    width: auto;
    height: 78px;
    margin: 60px auto 0px auto;
}

.section2-title-container h2 {
    width: auto;
    font-size: 48px;
    line-height: auto;
    letter-spacing: 0.48px;
    font-weight: bold;
    text-align: center;
    margin: 0px auto 0px auto;
    color: #000000;
}

.section2-title-container h2 span {
    color: #000000;
}

.section2-services-container-grid {
    width: 1352px;
    height: 950px !important;
    margin: 50px auto 0px auto;
    /* border: 1px solid #000000; */
    display: grid;
    grid-template-rows: 2fr 2fr 2fr 2fr;
    grid-template-columns: 2fr 2fr;
}

.section2-services-grid {
    width: 640px;
    height: 200px;
    margin: 0px auto 0px auto;
    /* border: 1px solid rgb(201, 201, 201, 0.5); */
    background-color: #FFFFFF;
}

.section2-services-grid:hover {
    height: 200px;
    margin: 0px auto 0px auto;
    border: 1px solid rgb(70, 147, 147, 0.5);
    box-shadow: -2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.section2-services-grid:hover .service-grid-description h3 {
    width: 605px;
    height: 22px;
    margin: 25px 0px 0px 20px;
}

.section2-services-grid:hover .service-grid-hover-text,
.section2-services-grid:hover .know-more {
    display: flex;
}

.section2-services-grid:hover .service-grid-image {
    display: none;
}

.service-grid-content-div {
    width: 605px;
    height: 170px;
    margin: 15px 11px 15px 25px;
    /* border: 1px solid #000000; */
    display: flex;
}

.service-grid-description {
    width: auto;
    height: auto;
    margin: 0px 0px 0px 0px;
}

.service-grid-description h3 {
    width: 200px;
    height: auto;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.2px;
    margin: 35px 0px 0px 25px;
}

.service-grid-description .service-grid-hover-text {
    display: none;
    width: 540px;
    height: auto;
    color: #000000;
    font-size: 16px;
    font-weight: regular;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin: 15px 0px 0px 20px;
}

.service-grid-description .know-more {
    display: none;
    width: 600px;
    height: 48px;
    color: #6E6BFF;
    font-size: 16px;
    font-weight: regular;
    letter-spacing: 0.16px;
    margin: 10px 0px 0px 20px;
    cursor: pointer;
}

.service-grid-image {
    height: 117px;
    width: 205px;
    overflow: hidden;
    margin: 22px 25px 31px 140px;
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.service-grid-image img {
    height: 100%;
    width: 100%;
    margin: 0px;
    border-radius: 5px;
}

.homepage-section3-container {
    width: 100%;
    height: 1465px;
    background-color: #FFFFFF;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.section3-title-container {
    width: auto;
    height: 78px;
    margin: 60px auto 0px auto;
}

.section3-title-container h2 {
    width: auto;
    font-size: 48px;
    line-height: auto;
    letter-spacing: 0.48px;
    font-weight: bold;
    text-align: center;
    margin: 0px auto 0px auto;
    color: #000000;
}

.section3-title-container h2 span {
    color: #000000;
}

.homepage-section3-content {
    width: 1250px;
    height: 1200px;
    margin: 50px auto 0px auto;
    /* border: 1px solid #000000; */
    display: flex;
    flex-direction: row;
}

.homepage-section3-techstack-grid-container {
    width: 702px;
    height: 1200px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
}

.homepage-section3-techstack-grid {
    width: 350px;
    height: 300px;
    border: 1px solid #33999933;
    margin: 0px;
    background-color: #FFFFFF;
}

.active {
    background-color: #3399991A;
}

.homepage-section3-techstack-grid-icon {
    margin: 40px 0px 0px 40px;
    height: 50px;
    width: 50px;
}

.homepage-section3-techstack-grid-icon img {
    height: 50px;
    width: 50px;
}

.homepage-section3-techstack-grid-content {
    width: 265px;
    height: 72px;
    /* margin: 129px 40px 0px 45px; */
    /* transition: transform 1000ms ease, opacity 1000ms ease; /* Smooth transition for both position and opacity 
  transform: translateY(100%); */
    opacity: 0;
    display: flex;
    flex-direction: column;
}

.active .homepage-section3-techstack-grid-content {
    width: 265px;
    height: 72px;
    /* margin: 98px 40px 0px 45px; */
    transform: translateY(0);
    /* Move content upwards when hovering */
    opacity: 1;
    margin: 0px 40px 0px 45px;

}

.grey-text {
    width: auto;
    height: auto;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.28px;
    text-align: right;
    color: #00000080;
    /* margin: 0px; */
    margin: 98px 40px 0px 45px;

}

.colored-text {
    width: auto;
    height: auto;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.28px;
    text-align: right;
    color: #339999;
    /* margin: 0px; */
    margin: 98px 40px 0px 45px;
}

.homepage-section3-techstack-grid-content p {
    font-size: 20px;
    display: none;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: right;
    color: #00000080;
    margin: 10px 0px 0px 0px;
}

.homepage-section3-techstack-grid:hover .homepage-section3-techstack-grid-content p {
    font-size: 20px;
    display: flex;
    justify-content: end;
    font-weight: bold;
    letter-spacing: 0.2px;
    text-align: right;
    color: #339999;
    margin: 10px 0px 0px 0px;
    width: auto;
    height: auto;
}

.homepage-section3-techstack-grid-description {
    width: 548px;
    height: 1206px;
    background: #F0F0F0;
    position: relative;
    overflow: hidden;
    /* display: flex; */
}

.homepoage-section3-techstack-grid-description-bg-image {
    width: 548px;
    height: 1206px;
    position: absolute;
    z-index: 1;
    /* margin: 0px; */
}

.homepoage-section3-techstack-grid-description-bg-image img {
    width: 548px;
    height: 1206px;
    /* object-fit: cover; */
    margin: 0px;
    opacity: 20%;
}

.homepage-section3-techstack-grid-side-content {
    width: 448px;
    height: 648px;
    /* border: 2px solid #000; */
    margin: 400px auto;
    position: sticky;
    top: auto;
    z-index: 2;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}


/* Active state: side content slides up and becomes visible */
.homepage-section3-techstack-grid-side-content.show {
    transform: translateY(0);
    opacity: 1;
}

.homepage-section3-techstack-grid-side-content-div {
    width: 100%;
    height: 100%;
    margin: 0px auto;
}

.homepage-section3-techstack-grid-side-content-div h2 {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.70);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: auto;
    letter-spacing: 0.8px;
    margin: 0px;
}

.homepage-section3-techstack-grid-side-content-div h4 {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.90);
    font-size: 24px;
    font-weight: 700;
    line-height: auto;
    letter-spacing: 0.48px;
    margin: 23px 0px 0px 0px;
}

.homepage-section3-techstack-grid-side-content-div h5 {
    width: 100%;
    height: auto;
    color: #339999;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 3px;
    margin: 23px 0px 0px 0px;
}

.homepage-section3-techstack-grid-side-content-div p {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.90);
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0.18px;
    margin: 23px 0px 0px 0px;
}

.homepage-section4-container {
    width: 100%;
    height: 1372px;
    background-color: #FFFFFF;
    margin: 0px auto;
    display: flex;
    flex-direction: column;

}

.section4-title-container {
    width: auto;
    height: 78px;
    margin: 60px auto 0px auto;
}

.section4-title-container h2 {
    width: auto;
    font-size: 48px;
    line-height: auto;
    letter-spacing: 0.48px;
    font-weight: bold;
    text-align: center;
    margin: 0px auto 0px auto;
    color: #000000;
}

.section4-title-container h2 span {
    color: #000000;
}

.homepage-section4-our-technical-process {
    width: 1102px;
    height: 1152px;
    margin: 50px auto;
    /* background-color:#F0F0F0; */
    display: flex;
    flex-direction: row;
    /* margin: 0px auto; */
}

.homepage-section4-otp-left-div {
    width: 550px;
    height: 1152px;
    /* background-color: #d3d1d180; */
}

.homepage-section4-otp-middle-pole {
    width: 2px;
    height: 1016px;
    background-color: #339999;
    margin: 136px 0px 0px 0px;
}

.homepage-section4-otp-right-div {
    width: 550px;
    height: 1152px;
    margin: 0px;
    /* background-color: #d3d1d180; */
}

.homepage-section4-otp-content-box1 {
    width: 465px;
    height: 92px;
    margin: 0px 24px 0px 64px;
    /* border: 1px solid #000000; */
}

.homepage-section4-otp-content-box1 h4 {
    width: 100%;
    height: 32px;
    font-size: 28px;
    letter-spacing: 0.28px;
    margin: 0px;
    font-weight: 500;
    text-align: left;
}

.homepage-section4-otp-content-box1 p {
    width: 100%;
    height: 44px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    text-align: left;
}

.homepage-section4-otp-border-line-div {
    width: 560px;
    height: 40px;
    /* border: 1px solid #9b1f1f; */
    margin: 28px 0px 0px 0px;
    display: flex;
}

.homepage-section4-otp-border-tick-div {
    width: 40px;
    height: 40px;
    margin: 0px;
    border: 1px solid #339999;
    border-radius: 50%;
    display: flex;
}

.homepage-section4-otp-border-tick-div img {
    width: 19px;
    height: 19px;
    margin: auto;
}

.homepage-section4-otp-border-line {
    width: 501px;
    height: 1px;
    background: rgb(51, 153, 153, 0.4);
    margin: auto 0px;
}

.homepage-section4-otp-border-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #339999;
    margin: auto 0px;
    z-index: 1;
}

.homepage-section4-otp-content-box2 {
    width: 465px;
    height: 92px;
    margin: 92px 24px 0px 64px;
    /* border: 1px solid #000000; */
}

.homepage-section4-otp-content-box2 h4 {
    width: 100%;
    height: 32px;
    font-size: 28px;
    letter-spacing: 0.28px;
    margin: 0px;
    font-weight: 500;
    text-align: left;
}

.homepage-section4-otp-content-box2 p {
    width: 100%;
    height: 44px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    text-align: left;
}

.homepage-section4-otp-content-box3 {
    width: 465px;
    height: 92px;
    margin: 114px 62px 0px 24px;
    /* border: 1px solid #000000; */
}

.homepage-section4-otp-content-box3 h4 {
    width: 100%;
    height: 32px;
    font-size: 28px;
    letter-spacing: 0.28px;
    margin: 0px;
    font-weight: 500;
    text-align: right;
}

.homepage-section4-otp-content-box3 p {
    width: 465px;
    height: 66px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    text-align: right;
}


.homepage-section4-otp-border-line-div2 {
    width: 560px;
    height: 40px;
    /* border: 1px solid #9b1f1f; */
    margin: 28px 0px 0px -10px;
    display: flex;
}

.homepage-section4-otp-border-tick-div {
    width: 40px;
    height: 40px;
    margin: 0px;
    border: 1px solid #339999;
    border-radius: 50%;
    display: flex;
}

.homepage-section4-otp-border-tick-div img {
    width: 19px;
    height: 19px;
    margin: auto;
}

.homepage-section4-otp-border-line {
    width: 501px;
    height: 1px;
    background: rgb(51, 153, 153, 0.4);
    margin: auto 0px;
}

.homepage-section4-otp-border-dot {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #339999;
    margin: auto 0px;
    z-index: 1;
}

.homepage-section4-otp-content-box4 {
    width: 465px;
    height: auto;
    margin: 92px 62px 0px 24px;
    /* border: 1px solid #000000; */
}

.homepage-section4-otp-content-box4 h4 {
    width: 100%;
    height: 32px;
    font-size: 28px;
    letter-spacing: 0.28px;
    margin: 0px;
    font-weight: 500;
    text-align: right;
}

.homepage-section4-otp-content-box4 p {
    width: 465px;
    height: 66px;
    font-size: 15px;
    letter-spacing: 0.15px;
    line-height: 22px;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    text-align: right;
}


.homepage-section5-container {
    width: 100%;
    height: 1261px;
    background-color: #F0F0F0;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
}

.section5-title-container {
    width: 570px;
    height: 120px;
    margin: 60px auto 0px auto;
}

.section5-title-container h2 {
    width: auto;
    font-size: 48px;
    line-height: auto;
    letter-spacing: 0.48px;
    font-weight: bold;
    text-align: center;
    margin: 0px auto 0px auto;
    color: #000000;
}

.section5-title-container h2 span {
    color: #000000;
}

.homepage-section5-content {
    width: 1117px;
    height: 921px;
    margin: 50px auto;
    display: flex;
}

.homepage-section5-our-values-container {
    display: flex;
}

.homepage-section5-our-values-left-div {
    width: 500px;
    height: 840px;
    margin: 0px;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-right-div {
    width: 500px;
    height: 840px;
    margin: 81px 0px 0px 117px;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-clear-communication-div {
    width: 500px;
    height: 350px;
    margin: 0px auto;
    background-color: #FF9A00;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-clear-communication-div img {
    width: 130px;
    height: 112px;
    margin: 22px auto 0px auto;
}

.homepage-section5-our-values-clear-communication-div h3 {
    width: 243px;
    height: 62px;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-clear-communication-div p {
    width: 420px;
    height: 93px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-innovation-div {
    width: 500px;
    height: 350px;
    margin: 0px auto;
    background-color: #17B271;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-innovation-div img {
    width: 130px;
    height: 112px;
    margin: 22px auto 0px auto;
}

.homepage-section5-our-values-innovation-div h3 {
    width: 232px;
    height: 62px;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.3px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-innovation-div p {
    width: 420px;
    height: 93px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-divider-line {
    width: 500px;
    height: 10px;
    margin: auto;
    display: flex;
}

.homepage-section5-our-values-divider-line-left-vector {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 0px 0px 0px;
    background-color: #339999;
}

.homepage-section5-our-values-divider-line-right-vector {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0px 0px 0px 0px;
    background-color: #339999;
}

.homepage-section5-our-values-divider-line-vector {
    width: 480px;
    height: 1px;
    margin: auto 0px;
    background-color: #339999;
}

.homepage-section5-our-values-customer-centric-div {
    width: 500px;
    height: 350px;
    margin: 0px auto;
    background-color: #9747FF;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-customer-centric-div img {
    width: 130px;
    height: 112px;
    margin: 22px auto 0px auto;
}

.homepage-section5-our-values-customer-centric-div h3 {
    width: 273px;
    height: 62px;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    /* letter-spacing: 0.3px; */
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-customer-centric-div p {
    width: 420px;
    height: 93px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-cost-effective-div {
    width: 500px;
    height: 350px;
    margin: 0px auto;
    background-color: #53C1DE;
    display: flex;
    flex-direction: column;
}

.homepage-section5-our-values-cost-effective-div img {
    width: 130px;
    height: 112px;
    margin: 22px auto 0px auto;
}

.homepage-section5-our-values-cost-effective-div h3 {
    width: 213px;
    height: 32px;
    font-size: 32px;
    text-align: center;
    font-weight: 700;
    /* letter-spacing: 0.3px; */
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section5-our-values-cost-effective-div p {
    width: 420px;
    height: 124px;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 31px;
    margin: 20px auto 0px auto;
    color: #000000;
}

.homepage-section6-container {
    width: 1532px;
    height: 500px;
    position: relative;
    overflow: hidden;
    display: flex;
}

.homepage-section6-background-image {
    width: 1352px;
    height: 400px;
    /* position: absolute; */
    z-index: 1;
    margin: 50px auto;
    display: flex;
    object-fit: fill;
}

.homepage-section6-background-image img {
    width: 1352px;
    height: 400px;
    margin: auto;
}

.homepage-section6-content {
    position: absolute;
    z-index: 2;
    width: 530px;
    height: 200px;
    margin: 80px 0px 0px 90px;
    /* border: 1px solid #FFFFFF; */
    color: #FFFFFF;
}

.homepage-section6-content h3 {
    width: 290px;
    height: auto;
    font-size: 32px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0.32px;
    line-height: auto;
    text-align: left;
    margin: 0px;
}

.homepage-section6-content p {
    width: 530px;
    height: auto;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 0.2px;
    line-height: auto;
    text-align: left;
    margin: 20px 0px 0px 0px;
}

.homepage-section6-content-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
    display: flex;
    margin: 40px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}

.homepage-section6-content-button p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #FFFFFF;
}

.homepage-section6-content-button img {
    margin: 0px 0px 0px 10px;
    width: 18px;
    height: auto;
}

.homepage-section7-container {
    width: 100%;
    margin: 0px auto;
    height: 851px;
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
}

.homepage-section7-background-image {
    width: 100%;
    height: 621px;
    overflow: hidden;
    margin: 50px auto;
    position: relative;
}

.homepage-section7-background-image img {
    width: 1532px;
    height: 621px;
    margin: 0px;
    object-fit: fill;
    position: absolute;
    z-index: 1;
}

.homepage-section7-background-layer {
    width: 1532px;
    height: 621px;
    position: absolute;
    z-index: 2;
    background-color: rgb(0, 0, 0, 0.4);
    /* border: 2px solid red; */
    display: flex;
}

.homepage-section7-our-values-grid {
    width: 275px;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.6);
    margin: auto auto;
}

.homepage-section7-our-values-grid h4 {
    width: auto;
    height: 31px;
    margin: 40px auto 0px auto;
    color: #FFFFFF;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
}

.homepage-section7-our-values-grid p {
    width: 260px;
    height: auto;
    margin: 20px auto 0px auto;
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    line-height: 25px;
    font-weight: 400;

}


@media only screen and (max-width: 432px) {
    .homepage-container {
        width: 100%;
        height: auto;
    }

    .homepage-section1-container {
        width: 100%;
        height: 680px;
        position: relative;
        overflow: hidden;
    }

    .homepage-section1-background-image {
        width: 100%;
        height: 650px;
        z-index: 1;
        position: absolute;
    }

    .homepage-section1-background-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .homepage-background-layer {
        width: 100%;
        height: 650px;
        /* background-color: rgb(0, 0, 0, 0.6); */
        margin: 0px;
        z-index: 2;
        position: absolute;
    }

    .section1-text-container {
        width: auto;
        height: 134px;
        margin: 258px auto;
    }

    .section1-text-container h2 {
        width: 334px;
        height: 70px;
        font-size: 28px;
        line-height: auto;
        letter-spacing: 0.28px;
        font-weight: bold;
        text-align: center;
        margin: 0px auto;
        color: #FFFCF3;
    }

    .section1-text-container p {
        width: 320px;
        height: auto;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.1px;
        font-weight: normal;
        text-align: center;
        margin: 10px auto;
        color: #FFFCF3;
    }

    .homepage-section2-container {
        width: 100%;
        height: 842px;
        background-color: #F0F0F0;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
    }

    .section2-title-container {
        width: auto;
        height: 26px;
        margin: 30px auto 0px auto;
    }

    .section2-title-container h2 {
        width: auto;
        font-size: 24px;
        line-height: auto;
        letter-spacing: 0.24px;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 0px auto;
        color: #000000;
    }

    .section2-services-container-grid {
        width: 340px;
        height: 720px !important;
        margin: 20px auto 0px auto;
        /* border: 1px solid #000000; */
        display: grid;
        grid-template-rows: 2fr 2fr 2fr 2fr;
        grid-template-columns: 2fr 2fr;
    }

    .section2-services-grid {
        width: 160px;
        height: 170px;
        margin: 0px auto 0px auto;
        /* border: 1px solid rgb(201, 201, 201, 0.5); */
        background-color: #FFFFFF;
    }

    .section2-services-grid:hover {
        height: 150px;
        margin: 0px auto 0px auto;
        border: none;
        box-shadow: none;
    }

    .section2-services-grid:hover .service-grid-description h3 {
        width: 135px;
        height: 22px;
        margin: 0px 0px 0px 0px;
    }

    .service-grid-content-div {
        width: 135px;
        height: auto;
        margin: 10px 0px 0px 10px;
        display: flex;
    }

    .service-grid-description h3 {
        width: 135px;
        height: auto;
        color: #000000;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.12px;
        margin: 0px auto 0px auto;
    }

    .service-grid-description .service-grid-hover-text,
    .service-grid-description:hover .service-grid-hover-text {
        display: flex;
        width: 135px;
        height: auto;
        color: #000000;
        font-size: 10px;
        font-weight: regular;
        line-height: 12px;
        letter-spacing: 0.1px;
        margin: 7px auto 0px auto;
    }

    .service-grid-description .know-more,
    .service-grid-description:hover .know-more {
        display: flex;
        width: 135px;
        height: auto;
        color: #6E6BFF;
        font-size: 10px;
        font-weight: regular;
        letter-spacing: 0.1px;
        margin: 10px 0px 0px 0px;
    }

    .homepage-section3-container {
        width: 350px;
        height: 721px;
        background-color: #FFFFFF;
        margin: 0px auto 0px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid #000; */
        /* flex-direction: column; */
    }

    .section3-title-container {
        width: auto;
        height: 26px;
        margin: 20px auto 0px auto;
    }

    .section3-title-container h2 {
        width: auto;
        font-size: 24px;
        line-height: auto;
        letter-spacing: 0.24px;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 0px auto;
        color: #000000;
    }

    .homepage-section3-content {
        width: 352px;
        height: 606px;
        margin: 25px 0px 0px 0px;
        /* border: 1px solid #000000; */
        /* background-color: #339999; */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .homepage-section3-techstack-grid-container{
        display: none;
    }
    .homepage-section3-techstack-grid-container-mob-responsive {
        width: 340px;
        height: 606px;
        display: grid;
        margin: 0px auto 0px auto;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }

    .homepage-section3-techstack-grid {
        width: 170px;
        height: 150px;
        border: 1px solid #33999933;
        margin: 0px;
        background-color: #FFFFFF;
    }

    .active {
        background-color: #3399991A;
    }

    .homepage-section3-techstack-grid-icon {
        margin: 20px 0px 0px 20px;
        height: 25px;
        width: 25px;
    }

    .homepage-section3-techstack-grid-icon img {
        height: 25px;
        width: 25px;
    }

    .homepage-section3-techstack-grid-content {
        width: 125px;
        height: 36px;
        /* margin: 129px 40px 0px 45px; */
        /* transition: transform 1000ms ease, opacity 1000ms ease; /* Smooth transition for both position and opacity 
      transform: translateY(100%); */
        opacity: 0;
        display: flex;
        flex-direction: column;
    }

    .homepage-section3-techstack-grid-content {
        width: 125px;
        height: 36px;
        margin: 49px 20px 0px 25px;
        transform: translateY(0);
        /* Move content upwards when hovering */
        opacity: 1;
        margin: 0px 0px 0px 0px;

    }

    .grey-text {
        width: auto;
        height: auto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.14px;
        text-align: right;
        color: #00000080;
        /* margin: 0px; */
        margin: 59px 20px 0px 0px;

    }

    .colored-text {
        width: auto;
        height: auto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.14px;
        text-align: right;
        color: #339999;
        /* margin: 0px; */
        margin: 49px 20px 0px 0px;
    }

    /* .homepage-section3-techstack-grid-content p {
        font-size: 10px;
        display: none;
        font-weight: bold;
        letter-spacing: 0.1px;
        text-align: right;
        color: #00000080;
        margin: 0px 0px 0px 0px;
    } */

    .homepage-section3-techstack-grid:hover .homepage-section3-techstack-grid-content p {
        font-size: 10px;
        display: flex;
        justify-content: end;
        font-weight: bold;
        letter-spacing: 0.1px;
        text-align: right;
        color: #339999;
        margin: 0px 0px 0px 25px;
        width: 130px;
        height: 12px;
    }
    .homepage-section3-techstack-grid-content p {
        font-size: 10px;
        display: flex;
        justify-content: end;
        font-weight: bold;
        letter-spacing: 0.1px;
        text-align: right;
        color: #339999;
        margin: 0px 0px 0px 25px;
        width: 130px;
        height: 12px;
    }

    .homepage-section3-techstack-grid-description {
        /* width: 548px; */
        /* height: 1206px; */
        background: #F0F0F0;
        position: relative;
        overflow: hidden;
        display: none;
    }

    .homepage-section4-container {
        width: 100%;
        height: 750px;
        background-color: #FFFFFF;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        /* border: 1px solid #000000; */

    }

    
.homepage-section3-techstack-grid-side-content-div {
    width: 220px;
    height: 300px;
    margin: 10px auto;
}
.title-div{
    width: 100%;
    height: 30px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-div h2 {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.70);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: auto;
    letter-spacing: 0.2px;
    margin: 0px;
}
.title-div img{
    width: 40px;
    height: 40px;
    margin: 0px;

}
.homepage-section3-techstack-grid-side-content-div h4 {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.90);
    font-size: 14px;
    font-weight: 700;
    line-height: auto;
    letter-spacing: 0.14px;
    margin: 10px 0px 0px 0px;
}

.homepage-section3-techstack-grid-side-content-div h5 {
    width: 220px;
    height: auto;
    color: #339999;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 2px;
    margin: 10px 0px 0px 0px;
}

.homepage-section3-techstack-grid-side-content-div p {
    width: 100%;
    height: auto;
    color: rgba(0, 0, 0, 0.90);
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.1px;
    margin: 10px 0px 0px 0px;
}

    .section4-title-container {
        width: auto;
        height: 26px;
        margin: 30px auto 0px auto;
    }

    .section4-title-container h2 {
        width: auto;
        font-size: 24px;
        line-height: auto;
        letter-spacing: 0.24px;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 0px auto;
        color: #000000;
    }

    .section4-title-container h2 span {
        color: #000000;
    }

    .homepage-section4-our-technical-process {
        width: 349px;
        height: 574px;
        margin: 30px auto 0px auto;
        display: flex;
        flex-direction: row;
        /* border: 1px solid #000000; */
    }

    .homepage-section4-otp-left-div {
        width: 174px;
        height: 574px;
        margin: 0px -7px 0px 0px;
        /* background-color: #d3d1d180; */
    }

    .homepage-section4-otp-middle-pole {
        width: 1px !important;
        height: 542px;
        background-color: #339999;
        margin: 80px auto 0px auto;
        /* z-index: 3; */
    }

    .homepage-section4-otp-right-div {
        width: 174px;
        height: 450px;
        margin: 0px auto;
        /* background-color: #d3d1d180; */
    }

    .homepage-section4-otp-content-box1 {
        width: 138px;
        height: 68px;
        margin: 0px 10px 0px 19px;
        /* border: 1px solid #000000; */
    }

    .homepage-section4-otp-content-box1 h4 {
        width: 100%;
        height: auto;
        font-size: 12px;
        letter-spacing: 0.12px;
        margin: 0px;
        font-weight: 500;
        text-align: left;
    }

    .homepage-section4-otp-content-box1 p {
        width: 100%;
        height: auto;
        font-size: 8px;
        letter-spacing: 0.08px;
        line-height: 10px;
        margin: 3px 0px 0px 0px;
        font-weight: 400;
        text-align: left;
    }

    .homepage-section4-otp-border-line-div {
        width: 174px;
        height: 20px;
        /* border: 1px solid #9b1f1f; */
        margin: 4px 0px 0px 0px;
        display: flex;
    }

    .homepage-section4-otp-border-tick-div {
        width: 20px;
        height: 20px;
        margin: 0px;
        border: 1px solid #339999;
        border-radius: 50%;
        display: flex;
    }

    .homepage-section4-otp-border-tick-div img {
        width: 9px;
        height: 9px;
        margin: auto;
    }

    .homepage-section4-otp-border-line {
        width: 145px;
        height: 1px;
        background: rgb(51, 153, 153, 0.4);
        margin: auto 0px;
    }

    .homepage-section4-otp-border-dot {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #339999;
        margin: auto 0px;
        z-index: 1;
    }

    .homepage-section4-otp-content-box2 {
        width: 138px;
        height: 68px;
        margin: 42px 0px 0px 19px;
        /* border: 1px solid #000000; */
    }

    .homepage-section4-otp-content-box2 h4 {
        width: 100%;
        height: auto;
        font-size: 12px;
        letter-spacing: 0.12px;
        margin: 0px;
        font-weight: 500;
        text-align: left;
    }

    .homepage-section4-otp-content-box2 p {
        width: 100%;
        height: 44px;
        font-size: 8px;
        letter-spacing: 0.08px;
        line-height: 10px;
        margin: 3px 0px 0px 0px;
        font-weight: 400;
        text-align: left;
    }


    .homepage-section4-otp-content-box3 {
        width: 138px;
        height: 68px;
        margin: 87px 20px 0px 5px;
        /* border: 1px solid #000000; */
    }

    .homepage-section4-otp-content-box3 h4 {
        width: 100%;
        height: auto;
        font-size: 12px;
        letter-spacing: 0.12px;
        margin: 0px;
        font-weight: 500;
        text-align: right;
    }

    .homepage-section4-otp-content-box3 p {
        width: 100%;
        height: auto;
        font-size: 8px;
        letter-spacing: 0.08px;
        line-height: 10px;
        margin: 3px 0px 0px 0px;
        font-weight: 400;
        text-align: right;
    }

    .homepage-section4-otp-border-line-div2 {
        width: 175px;
        height: 20px;
        /* border: 1px solid #9b1f1f; */
        margin: 5px 0px 0px -8px;
        display: flex;
    }

    .homepage-section4-otp-border-tick-div {
        width: 20px;
        height: 20px;
        margin: 0px;
        border: 1px solid #339999;
        border-radius: 50%;
        display: flex;
    }

    .homepage-section4-otp-content-box4 {
        width: 138px;
        height: auto;
        margin: 42px 20px 0px 5px;
        /* border: 1px solid #000000; */
    }

    .homepage-section4-otp-content-box4 h4 {
        width: 100%;
        height: auto;
        font-size: 12px;
        letter-spacing: 0.12px;
        margin: 0px;
        font-weight: 500;
        text-align: right;
    }

    .homepage-section4-otp-content-box4 p {
        width: 100%;
        height: auto;
        font-size: 8px;
        letter-spacing: 0.08px;
        line-height: 10px;
        margin: 3px 0px 0px 0px;
        font-weight: 400;
        text-align: right;
    }

    .homepage-section5-container {
        width: 100%;
        height: 370px;
        background-color: #F0F0F0;
        margin: 0px auto 0px auto;
        display: flex;
        flex-direction: column;
    }

    .section5-title-container {
        width: 295px;
        height: 60px;
        display: flex;
        margin: 30px auto 0px auto;
    }

    .section5-title-container h2 {
        width: auto;
        font-size: 24px;
        line-height: auto;
        letter-spacing: 0.24px;
        font-weight: bold;
        text-align: center;
        margin: 0px auto 0px auto;
        color: #000000;
    }

    .section5-title-container h2 span {
        color: #000000;
    }

    .homepage-section5-content {
        width: 340px;
        height: 300px;
        margin: 25px auto;
        display: flex;
    }

    .homepage-section5-our-values-container {
        display: none;
    }


    .homepage-section5-carousal-container {
        width: 340px;
        height: 234px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items:center;
        margin: 0px auto 0px auto;
    }

    .homepage-section5-carousal {
        width: 340px;
        height: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px auto 0px auto;
    }

    .section5-slide {
        width: 300px;
        height: 200px;
        margin: 0px 5px;
        position: relative;
        /* border: 1px solid #000000; */
    }

    .active-section5-slide {

        display: flex;
        /* Show only active slide */
        opacity: 1;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }

    .inactive-section5-slide {
        display: none;
        opacity: 0;
    }

    .active-section5-slide img {
        width: 65px;
        height: 56px;
        margin: 15px auto 0px auto;
    }

    .active-section5-slide h3 {
        width: 138px;
        height: 48px;
        margin: 10px auto 0px auto;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-align: center;
    }

    .active-section5-slide p {
        width: 240px;
        height: 45px;
        margin: 10px auto 0px auto;
        font-size: 10px;
        color: #000000;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.1px;
        text-align: center;
    }

    .section5-radio-controls {
        width: 72px;
        height: 24px;
        margin: 5px auto 0px auto;
        /* background: #339999; */
        display: flex;
    }

    .section5-radio-container {
        width: 8px;
        height: 8px;
        display: flex;
        border-radius: 50%;
        background: #D9D9D9;
        margin: auto 5px;
    }

    .section5-radio-container-checked {
        background: #000000;
    }

    .slide-btn {
        width: 20px;
        height: 20px;
        margin: auto 0px;
    }

    .slide-btn img {
        width: 15px;
        height: 15px;
        margin: auto 0px auto 0px;
    }

    .homepage-section6-container {
        width: 100%;
        height: 218px;
        display: flex;
        position: relative;
        overflow: hidden;
    }

    .homepage-section6-background-image {
        width: 352px;
        height: 138px;
        z-index: 1;
        margin: 40px auto;
        display: flex;
        object-fit: fill;
    }

    .homepage-section6-background-image img {
        width: 352px;
        height: 138px;
        margin: auto;
    }

    .homepage-section6-content {
        position: absolute;
        z-index: 2;
        width: 290px;
        height: 63px;
        margin: 20px 0px 0px 30px;
        color: #FFFFFF;
    }

    .homepage-section6-content h3 {
        width: 110px;
        height: auto;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
        letter-spacing: 0.16px;
        line-height: auto;
        text-align: left;
        margin: 0px;
    }

    .homepage-section6-content p {
        width: 201px;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 0.1px;
        line-height: auto;
        text-align: left;
        margin: 5px 0px 0px 0px;
    }

    .homepage-section6-content-button {
        width: 60px;
        height: 20px;
        border-radius: 2px;
        background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
        display: flex;
        margin: 5px 0px 0px 0px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: none;
    }

    .homepage-section6-content-button p {
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.08px;
        width: 100%;
        height: auto;
        text-align: center;
        margin: auto;
        color: #FFFFFF;
    }

    .homepage-section6-content-button img {
        margin: 0px 0px 0px 10px;
        width: 18px;
        height: auto;
        display: none;
    }

    .homepage-section7-container {
        width: 100%;
        margin: 0px auto;
        height: 401px;
        background-color: #F0F0F0;
        display: flex;
        flex-direction: column;
    }

    .homepage-section7-background-image {
        width: 83%;
        height: 258px;
        overflow: hidden;
        margin: 30px auto;
        position: relative;
    }

    .homepage-section7-background-image img {
        width: 100%;
        height: 258px;
        margin: 0px;
        object-fit: fill;
        position: absolute;
        z-index: 1;
    }

    .homepage-section7-background-layer {
        width: 100%;
        height: 258px;
        position: absolute;
        z-index: 2;
        background-color: rgb(0, 0, 0, 0.4);
        /* border: 2px solid red; */
        display: flex;
    }

    .homepage-section7-our-values-grid {
        width: 275px;
        height: 350px;
        background-color: rgba(0, 0, 0, 0.6);
        margin: auto auto;
        display: none;
    }

    .homepage-section7-carousal-container {
        width: 260px;
        height: 235px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0px auto;
    }

    .slide-white-btn {
        width: 25px;
        height: 25px;
        margin: auto 5px;
    }

    .slide-white-btn img {
        width: 7.5px;
        height: 15px;
        margin: auto 0px;
    }

    .homepage-section7-carousal {
        width: 180px;
        height: 235px;
        display: flex;
        flex-direction: column;
        margin: 17px auto 0px auto;
    }

    .section7-slide {
        width: 180px !important;
        height: 224px;
        background-color: rgba(0, 0, 0, 0.6);
        margin: auto auto;
    }

    .active-section7-slide {
        display: flex;
        /* Show only active slide */
        opacity: 1;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }

    .inactive-section7-slide {
        display: none;
        opacity: 0;
    }

    .section7-radio-controls {
        width: 80px;
        height: 7px;
        margin: 5px auto 0px auto;
        /* background: #339999; */
        display: flex;
    }

    .section7-radio-container {
        width: 8px;
        height: 8px;
        display: flex;
        border-radius: 50%;
        background: #D9D9D9;
        margin: auto 5px;
    }

    .section7-radio-container-checked {
        background: #000000;
    }

    .section7-slide h3 {
        width: 100%;
        height: 24px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.16px;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        margin: 50px auto 0px auto;
    }

    .section7-slide p {
        width: 80%;
        height: auto;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        margin: 20px auto 0px auto;

    }


}

@media (hover: hover) and (pointer: coarse) {
    .section2-services-grid:hover {
        border: none;
        box-shadow: none;
        pointer-events: none;
    }

    .section2-services-grid:pointer {
        border: none;
        box-shadow: none;
        pointer-events: none;
    }

    .section2-services-grid:hover .know-more {
        pointer-events: auto
            /* Keeps anchor tag clickable */
    }

}