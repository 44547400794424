.services-page-section5-form-div {
    width: 737px;
    height: 706px;
    margin: 20px auto 0px auto;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255, 0.1);
}

.services-page-section-enquiry-form-div {
    width: auto;
    height: auto;
    margin: auto;
}

.services-page-section-enquiry-form-div p {
    width: 478px;
    height: 60px;
    margin: 10px auto 0px auto;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    color: #000000;
    font-weight: 300;
    text-align: center;
}

.contact-us-form {
    width: 620px;
    height: auto;
    margin: 17px auto 0px auto;
}

.input-row-enq-form {
    width: 620px;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin: 0px auto 25px auto;
}

.input-message-row {
    width: 620px;
    height: 150px;
    display: flex;
    flex-direction: row;
    margin: 0px auto 25px auto;
}

.enq-form-fullname-group,
.enq-form-phone-group {
    width: 300px;
    height: 50px;
    margin: 0px 10px 0px 0px;
}

.company-group {
    width: 620px;
    height: 50px;
    margin: 0px 0px 0px 0px;
}

.enq-form-formdata-company {
    width: 600px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    font-size: 16px;
}

.enq-form-message-group {
    width: 620px;
    height: 150px;
    margin: 0px 0px 0px 0px;
}

.enq-form-formdata-message {
    width: 600px;
    height: 150px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 15px 0px 0px 20px;
    font-size: 16px;
    resize: none;
    font-family: Archivo;
    letter-spacing: 0.16px;
    line-height: 1.5;
}

.enq-form-formdata-fullname,
.enq-form-formdata-phone {
    width: 280px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    font-size: 16px;
    letter-spacing: 0.16px;
    line-height: 1.5;
}

.enq-form-email-group,
.enq-form-website-group {
    width: 300px;
    height: 50px;
    margin: 0px 0px 0px 10px;
}

.enq-form-formdata-email,
.enq-form-formdata-website {
    width: 280px;
    height: 50px;
    margin: 0px auto 0px auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-size: 16px;
    outline: none;
    border: none;
    padding: 0px 0px 0px 20px;
    letter-spacing: 0.16px;
    line-height: 1.5;
}

.contact-us-submit-button-div {
    width: 120px;
    height: 40px;
    margin: 45px auto 0px auto;
}

.services-page-form-submit-button {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    border: none;
    background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
    display: flex;
    margin: 0px auto 0px auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.services-page-form-submit-button p {
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.16px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 0px;
    color: #FFFFFF;
}

.valid-feedback {
    display: none;
}

.invalid-feedback {
    margin: 2px 0px 0px 10px;
    height: 13px;
    width: auto;
    display: flex;
    justify-content: start;
    align-items: center;
}

.invalid-feedback p {
    font-size: 12px;
    width: auto;
    margin: 0px 0px 0px 5px;
    color: rgb(0, 0, 0);
    height: auto;
    font-weight: 500;
}

.invalid-feedback img {
    width: auto;
    margin: 0px 0px 0px 0px;
    height: auto;
}

.form-seperator-div {
    width: 600px;
    height: 0px;
    border: 1px solid #000000;
    border-radius: 50%;
    margin: 12px auto 0px auto;
}

.whatsapp-form {
    width: 541px;
    height: 50px;
    margin: 10px auto 0px auto;
    /* border: 1px solid #000000; */
    display: flex;
    justify-content: space-between;
}

.whatsapp-extension-div {
    width: 90px;
    height: 50px;
    display: flex;
    background: #FFFFFF;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.whatsapp-extension-group {
    width: 90px;
    height: 50px;
    text-align: center;
    appearance: none;
    /* For modern browsers */
    -moz-appearance: none;
    /* For Firefox */
    -webkit-appearance: none;
    /* For Safari */
    background: transparent;
    /* Remove default background */
    border: none;
    /* Optional: Remove border */
    outline: none;
    font-size: 16px;
    font-weight: 500;
}

#country-codes {
    width: 90px;
}

.country-code-list-item {
    width: 90px !important;
    height: 40px !important;
}

.whatsapp-submit-button {
    width: 125px;
    height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    background: #FFFFFF;
}

.whatsapp-submit-button p {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 600;
    margin: auto 10px auto 10px;
}

.whatsapp-submit-button img {
    width: 35px;
    height: 35px;
    margin: auto 0px auto 5px;
}

.whatsapp-enq-form-phone-group {
    width: 300px;
    height: 50px;
    margin: 0px 0px 0px 0px;
}

@media only screen and (max-width: 432px) {
    .services-page-section5-form-div {
        width: 330px !important;
        height: 499px;
        margin: 20px auto 0px auto;
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255, 0.3);
    }

    .services-page-section-enquiry-form-div {
        width: 242px;
        height: 350px;
        margin: 20px auto 0px auto;
    }

    .services-page-section-enquiry-form-div p {
        width: 242px;
        height: 30px;
        margin: 10px auto 0px auto;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.1px;
        color: #000000;
        font-weight: 300;
        text-align: center;
    }


    .contact-us-form {
        width: 200px;
        height: 320px;
        margin: 20px auto 0px auto;
        /* border: 1px solid #000000; */
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-row-enq-form-1,
    .input-row-enq-form-2 {
        width: 200px !important;
        height: 65px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        margin: 0px auto 15px auto;
    }

    .input-row-enq-form-3 {
        width: 200px !important;
        height: 25px !important;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        margin: 0px auto 15px auto;
    }

    .input-message-row {
        width: 200px;
        height: 75px;
        display: flex;
        /* flex-direction: row; */
        margin: 0px auto 0px auto;
    }

    .enq-form-fullname-group,
    .enq-form-phone-group {
        width: 200px;
        height: 25px;
        margin: 15px 0px 0px 0px;
    }

    .enq-form-email-group,
    .enq-form-website-group {
        width: 200px;
        height: 25px;
        margin: 15px 0px 0px 0px;
    }

    .enq-form-formdata-fullname,
    .enq-form-formdata-phone {
        width: 190px;
        height: 25px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 1.5;
    }


    .enq-form-company-group {
        width: 200px;
        height: 25px;
        margin: 15px 0px 0px 0px;
    }

    .enq-form-formdata-company {
        width: 190px;
        height: 25px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        font-size: 10px;
    }

    .enq-form-message-group {
        width: 200px;
        height: 75px;
        margin: 15px 0px 0px 0px;
    }

    .enq-form-formdata-message {
        width: 190px;
        height: 55px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        outline: none;
        border: none;
        padding: 10px 0px 0px 10px;
        font-size: 10px;
        resize: none;
        font-family: Archivo;
        letter-spacing: 0.1px;
        line-height: 1.5;
    }



    .enq-form-formdata-email,
    .enq-form-formdata-website {
        width: 190px;
        height: 25px;
        margin: 0px auto 0px auto;
        border-radius: 2px;
        background-color: #FFFFFF;
        font-size: 10px;
        outline: none;
        border: none;
        padding: 0px 0px 0px 10px;
        letter-spacing: 0.1px;
        line-height: 1.5;
    }


    .contact-us-submit-button-div {
        width: 80px !important;
        height: 30px;
        margin: 15px auto 0px auto;
    }

    .services-page-form-submit-button {
        width: 80px !important;
        height: 30px !important;
        border-radius: 2px;
        border: none;
        background: linear-gradient(to right, #F0C632 0%, #FA4848CC 80%);
        display: flex;
        margin: 0px auto 0px auto;
        justify-content: center;
        align-items: center;

    }

    .services-page-form-submit-button p {
        font-weight: bold;
        font-size: 10px !important;
        letter-spacing: 0.2px;
        width: auto;
        height: auto;
        text-align: center;
        margin: auto 0px auto 0px;
        color: #FFFFFF;
    }


}