.about-page-container {
  width: 1532px;
  height: auto;
  overflow: hidden;
}

.about-page-section1-container {
  width: 100%;
  height: 772px;
  display: flex;
  position: relative;
}

.about-page-section1-background-image {
  width: 100%;
  height: 772px;
  margin: 0px;
  position: relative;
}

.about-page-section1-background-image img {
  width: 1532px;
  height: 772px;
  object-fit: fill;
}

.about-page-section1-backgorund-layer {
  width: 1532px;
  height: 772px;
  position: absolute;
  z-index: 2;
  background-color: rgb(0, 0, 0, 0.5);
}

.about-page-section1-description {
  width: 738px;
  height: auto;
  margin: 150px 0px 0px 96px;
}

.about-page-section1-description h2 {
  width: 738px;
  height: auto;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 0.42px;
  line-height: 54px;
  margin: 0px;
  align-items: left;
  color: #ffffff;
}

.about-page-section1-description p {
  width: 720px;
  height: auto;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 30px;
  margin: 46px 0px 0px 0px;
  align-items: left;
  color: #ffffff;
}

.about-page-contact-us-container-button {
  width: 176px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
  display: flex;
  margin: 46px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.about-page-contact-us-container-button p {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 10px;
  color: #ffffff;
}

.about-page-contact-us-container-button img {
  margin: 0px 0px 0px 10px;
  width: 18px;
  height: auto;
}

.about-page-section2-container {
  width: 100%;
  height: 868px;
  margin: 0px auto 0px auto;
  background-color: rgb(65, 75, 77, 0.11);
  display: flex;
}

.about-page-section2-description {
  width: 600px;
  height: 742px;
  /* background-color: #F0C632; */
  margin: 63px 0px 0px 90px;
}

.about-page-section2-image {
  width: 600px;
  height: 648px;
  margin: auto 0px auto 152px;
  /* border: 2px solid #000; */
}

.about-page-section2-image img {
  width: 600px;
  height: 648px;
  margin: 0px;
  object-fit: fill;
}

.about-page-section2-description h3 {
  width: 193px;
  height: 45px;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 1.5;
  color: #000000;
  margin: 30px auto 0px auto;
}

.about-page-section2-description h3 span {
  color: #000000;
}

.about-page-section2-description p {
  width: 500px;
  height: 300px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 30px;
  color: #000000;
  margin: 35px auto 0px auto;
}

.about-page-section3-container {
  width: 100%;
  height: 500px;
  display: flex;
  position: relative;
  /* background: #000000; */
}

.about-page-section3-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about-page-section3-description {
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.6);
  position: absolute;
  z-index: 1;
}
.about-page-section3-description-content {
  width: 1200px;
  height: 416px;
  margin: 37px auto 0px auto;
}
.about-page-section3-description-content h2 {
  width: 253px;
  height: 56px;
  margin: 0px auto 0px auto;
  font-size: 42px;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 0.42px;
  line-height: 56px;
}

.about-page-section3-description-content h2 span {
  color: #ffffff;
}

.about-page-section3-description-content p {
  width: 1200px;
  height: auto;
  margin: 20px auto 0px auto;
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.28px;
  line-height: 40px;
  text-align: center;
}

.about-page-section4-container {
  width: 100%;
  height: 680px;
  display: flex;
  background-color: #d7d9d9;
}

.about-page-section4-image {
  width: 544px;
  height: 540px;
  margin: 70px 0px 0px 90px;
}

.about-page-section4-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about-page-section4-description {
  width: 660px;
  height: 335px;
  margin: auto 90px auto 148px;
  /* border: 1px solid #1c6f6f; */
}

.about-page-section4-description h3 {
  width: 193px;
  height: 45px;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 1.5;
  color: #000000;
  margin: 30px auto 0px auto;
}

.about-page-section4-description h3 span {
  color: #000000;
}

.about-page-section4-description p {
  width: 600px;
  height: 180px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 30px;
  color: #000000;
  margin: 50px auto 0px auto;
  text-align: center;
}

.about-page-section5-container {
  width: 100%;
  height: 680px;
  display: flex;
  background-color: #eaf0f4;
}

.about-page-section5-image {
  width: 544px;
  height: 540px;
  margin: 70px 90px 0px 148px;
}

.about-page-section5-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about-page-section5-description {
  width: 660px;
  height: 335px;
  margin: auto 0px auto 90px;
  /* border: 1px solid #1c6f6f; */
}

.about-page-section5-description h3 {
  width: 239px;
  height: 45px;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 1.5;
  color: #000000;
  margin: 30px auto 0px auto;
}

.about-page-section5-description h3 span {
  color: #000000;
}

.about-page-section5-description p {
  width: 600px;
  height: 150px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 30px;
  color: #000000;
  margin: 50px auto 0px auto;
  text-align: center;
}

.about-page-section6-container {
  width: 100%;
  height: 792px;
  display: flex;
  position: relative;
}

.about-page-section6-image {
  width: 100%;
  height: 100%;
  margin: 0px auto 0px auto;
}

.about-page-section6-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about-page-section6-description {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: rgb(0, 0, 0, 0.5);
  display: flex;
}

.about-page-section6-form-div {
  width: 737px;
  height: 578px;
  margin: 135px 0px 0px 90px;
  background: rgb(255, 255, 255, 0.45);
  display: flex;
  flex-direction: column;
}

.about-page-section6-form-div p {
  width: 478px;
  height: 60px;
  margin: 17px auto 0px auto;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #000000;
  font-weight: 300;
  text-align: center;
}

.submit-button-div {
  width: 120px;
  height: 40px;
  margin: 25px auto 0px auto;
}

.services-page-form-submit-button {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  border: none;
  background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
  display: flex;
  margin: 0px auto 0px auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.services-page-form-submit-button p {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 0px;
  color: #ffffff;
}

.about-page-section6-location-div {
  width: 592px;
  height: 539px;
  /* background-color: #e34747; */
  margin: 135px 90px 0px 30px;
}

.about-page-section6-location-div h3 {
  width: 300px;
  height: 45px;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 45px;
  margin: 0px auto;
  color: #ffffff;
}

.about-page-section6-location-image {
  width: 592px;
  height: 354px;
  margin: 16px 0px 0px 0px;
}

.about-page-section6-location-image iframe {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.about-page-section6-location-description {
  width: 592px;
  height: 130px;
  margin: 0px 0px 0px 0px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.about-page-section6-location-description p {
  width: 271px;
  height: 31px;
  margin: 30px auto 0px auto;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #000000;
}

.about-page-section6-location-description p:last-child {
  margin: 0px auto 0px auto;
}

.contact-form-adjustment {
  margin: auto 0px auto 90px;
  background: rgb(255, 255, 255, 0.4);
  height: fit-content;
}

@media only screen and (max-width: 432px) {
  .about-page-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .about-page-section1-container {
    width: 100%;
    height: 750px;
    display: flex;
    position: relative;
  }

  .about-page-section1-background-image {
    width: 100%;
    height: 750px;
    margin: 0px;
    position: relative;
  }

  .about-page-section1-background-image img {
    width: 100%;
    height: 750px;
    object-fit: fill;
  }

  .about-page-section1-backgorund-layer {
    width: 100%;
    height: 750px;
    position: absolute;
    z-index: 2;
    background-color: rgb(0, 0, 0, 0.5);
  }

  .about-page-section1-description {
    width: 280px;
    height: 183px;
    margin: 284px auto 0px auto;
  }

  .about-page-section1-description h2 {
    width: 280px;
    height: auto;
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0.21px;
    line-height: 27px;
    margin: 0px;
    align-items: left;
    color: #ffffff;
  }

  .about-page-section1-description p {
    width: 280px;
    height: auto;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    margin: 15px 0px 0px 0px;
    align-items: left;
    color: #ffffff;
  }

  .about-page-contact-us-container-button {
    width: 95px;
    height: 30px;
    border-radius: 2px;
    background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
    display: flex;
    margin: 15px 0px 0px 0px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }

  .about-page-contact-us-container-button p {
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.1px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #ffffff;
  }

  .about-page-contact-us-container-button img {
    margin: 0px 0px 0px 10px;
    width: 10px;
    height: auto;
  }

  .about-page-section2-container {
    width: 100%;
    height: 759px;
    margin: 0px auto 0px auto;
    background-color: rgb(65, 75, 77, 0.11);
    display: flex;
    flex-direction: column;
  }

  .about-page-section2-description {
    width: 270px;
    height: 350px;
    /* background-color: #F0C632; */
    margin: 0px auto 0px auto;
    display: flex;
    flex-direction: column;
  }

  .about-page-section2-image {
    width: 300px;
    height: 324px;
    margin: 35px auto 0px auto;
    /* border: 2px solid #000; */
  }

  .about-page-section2-image img {
    width: 300px;
    height: 324px;
    margin: 0px;
    object-fit: fill;
  }

  .about-page-section2-description h3 {
    width: 141px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 1.5;
    color: #000000;
    margin: 30px auto 0px auto;
  }

  .about-page-section2-description p {
    width: 280px;
    height: auto;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    color: #000000;
    margin: 15px auto 0px auto;
  }

  .about-page-section3-container {
    width: 100%;
    height: 381px;
    display: flex;
    position: relative;
    /* background: #000000; */
  }

  .about-page-section3-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .about-page-section3-description {
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.6);
    position: absolute;
    z-index: 1;
  }

  .about-page-section3-description-content {
    width: 360px;
    height: auto;
    margin: 45px auto 0px auto;
  }

  .about-page-section3-description-content h2 {
    width: 360px;
    height: 28px;
    margin: 0px auto 0px auto;
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 0.21px;
    line-height: 27px;
    text-align: center;
  }

  .about-page-section3-description-content h2 span {
    color: #ffffff;
  }

  .about-page-section3-description-content p {
    width: 320px;
    height: auto;
    margin: 10px auto 0px auto;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 0.12px;
    line-height: 18px;
    text-align: center;
  }

  .about-page-section4-container {
    width: 100%;
    height: 521px;
    display: flex;
    flex-direction: column-reverse;
    background-color: #d7d9d9;
  }

  .about-page-section4-image {
    width: 272px;
    height: 270px;
    margin: 25px auto 25px auto;
  }

  .about-page-section4-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .about-page-section4-description {
    width: 260px;
    height: 166px;
    margin: 0px auto 0px auto;
    /* border: 1px solid #1c6f6f; */
  }

  .about-page-section4-description h3 {
    width: 126px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 1.5;
    color: #000000;
    margin: 0px auto 0px auto;
  }

  .about-page-section4-description h3 span {
    color: #000000;
  }

  .about-page-section4-description p {
    width: 260px;
    height: 105px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    color: #000000;
    margin: 10px auto 0px auto;
    text-align: center;
  }

  .about-page-section5-container {
    width: 100%;
    height: 491px;
    display: flex;
    flex-direction: column;
    background-color: #eaf0f4;
  }

  .about-page-section5-image {
    width: 272px;
    height: 270px;
    margin: 25px auto 0px auto;
  }

  .about-page-section5-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .about-page-section5-description {
    width: 260px;
    height: 136px;
    margin: 30px auto 0px auto;
    /* border: 1px solid #1c6f6f; */
  }

  .about-page-section5-description h3 {
    width: 149px;
    height: 36px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 1.5;
    color: #000000;
    margin: 0px auto 0px auto;
  }

  .about-page-section5-description h3 span {
    color: #000000;
  }

  .about-page-section5-description p {
    width: 260px;
    height: 75px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 15px;
    color: #000000;
    margin: 25px auto 0px auto;
    text-align: center;
  }

  .about-page-section6-container {
    width: 100%;
    height: 952px;
    display: flex;
    position: relative;
  }

  .about-page-section6-image {
    width: 100%;
    height: 952px;
    margin: 0px auto 0px auto;
  }

  .about-page-section6-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .about-page-section6-description {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgb(0, 0, 0, 0.5);
    display: flex;
    justify-content: start;
    flex-direction: column-reverse;
  }


  .about-page-section6-location-div {
    width: 300px;
    height: 308px;
    /* background-color: #e34747; */
    margin: 30px auto 0px auto;
  }

  .about-page-section6-location-div h3 {
    width: 209px;
    height: 32px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 32px;
    margin: 0px auto 0px auto;
    color: #ffffff;
  }

  .about-page-section6-location-image {
    width: 300px;
    height: 180px;
    margin: 30px auto 0px auto;
  }

  .about-page-section6-location-image iframe {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .about-page-section6-location-description {
    width: 300px;
    height: 65px;
    margin: 0px 0px 0px 0px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
  }

  .about-page-section6-location-description p {
    width: 136px;
    height: auto;
    margin: 15px auto 0px auto;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #000000;
  }

  .about-page-section6-location-description p:last-child {
    margin: 0px auto 0px auto;
  }

  .contact-form-adjustment {
    margin: 25px auto 0px auto;
    background: rgb(255, 255, 255, 0.4);
    height: fit-content;
  }
}
