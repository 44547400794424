.privacy-policy-main-container {
    width: 1532px;
    height: auto;
    /* display: flex; */
    overflow: hidden;
}

.pp-title-container {
    width: 310px;
    height: 48px;
    margin: 60px auto 0px auto;
    display: flex;
    
}
.pp-title-container h3{
    text-align: center;
    margin: 0px auto 0px auto;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    letter-spacing: 0.36px;
}
.last-updated-container{
    width: 100%;
    display: flex;
    justify-content: end;
    height: 27px;
    margin: 25px 210px 0px 0px;
}
.last-updated-container p{
    width: 284px;
    height: auto;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 27px;
    margin: 0px 210px 0px 0px;
}
.pp-description-container{
    width: 1080px;
    height: 4181px;
    margin: 75px auto 0px auto;    
    /* border: 1px solid #000; */
}
.pp-description-container h4{
    width: 100%;
    height: auto;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    letter-spacing: 0.18px;
    margin: 20px 0px 0px 0px;
}
.pp-description-container p{
    width: 100%;
    height: auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.16px;
    font-weight: 400;
    margin: 10px auto 0px auto;
}
.pp-description-container ol{
    margin: 12px 0px 0px -10px;
}
.pp-description-container li{
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.16px;
    line-height: 24px;
    margin: 10px auto 0px auto;
}


@media only screen and (max-width: 432px) {
    .privacy-policy-main-container {
        width: 100%;
        height: auto;
        /* display: flex; */
        overflow: hidden;
    }
    .pp-title-container {
        width: 206px;
        height: 44px;
        margin: 45px auto 0px auto;
        display: flex;
    }
    .pp-title-container h3{
        width: 100%;
        text-align: center;
        margin: 0px auto 0px auto;
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: 0.24px;
    }
    .last-updated-container{
        width: 100%;
        display: flex;
        justify-content: end;
        height: 15px;
        margin: 22px 50px 0px 0px;
    }
    .last-updated-container p{
        width: 158px;
        height: auto;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: 0.1px;
        line-height: 15px;
        margin: 0px 50px 0px 0px;
    }
    .pp-description-container{
        width: 330px;
        height: auto;
        margin: 35px auto 50px auto;    
        /* border: 1px solid #000; */
    }
    .pp-description-container h4{
        width: 100%;
        height: auto;
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 0.12px;
        margin: 20px 0px 0px 0px;
    }
 
    .pp-description-container ol{
        margin: 8px 0px 0px 15px;
        padding: 0px;
    }
    .pp-description-container li{
        width: 100%;
        height: auto;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 15px;
        margin: 7px auto 0px auto;
    }
    
}