.our-products-container {
  width: 1532px;
  height: auto;
  overflow: hidden;
}

.our-products-section1-container {
  width: 1532px;
  height: 842px;
  display: flex;
  /* position: relative; */
  /* overflow: hidden; */
}

.our-products-section1-background-image {
  width: 1532px !important;
  height: 842px !important;
  margin: 0px;
}

.our-products-section1-background-image img {
  width: 1532px !important;
  height: 842px !important;
  margin: 0px;
  object-fit: fill;
}

.our-products-background-layer {
  width: 1532px;
  height: 842px;
  background-color: rgb(0, 0, 0, 0.7);
  margin: 0px;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.bestbudget-description-container {
  width: 552px !important;
  height: 500px !important;
  margin: 140px 50px 0px 90px;
  /* border: 3px solid #fdfdfd; */
}

.bestbudget-description-container h2 {
  width: 100%;
  height: auto;
  text-align: left;
  margin: 0px 0px;
  font-size: 42px;
  font-family: Impact, sans-serif;
  font-weight: normal;
  letter-spacing: 0.42px;
  line-height: 48px;
  color: #ffffff;
}

.bestbudget-description-container p {
  width: 100%;
  height: auto;
  text-align: left;
  margin: 20px 0px 0px 0px;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 32px;
  color: #ffffff;
}

.our-products-explore-now-container-button {
  width: 190px;
  height: 40px;
  border-radius: 4px;
  background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
  display: flex;
  margin: 50px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.our-products-explore-now-container-button p {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.16px;
  width: auto;
  height: auto;
  text-align: center;
  margin: auto 0px auto 10px;
  color: #ffffff;
}

.our-products-explore-now-container-button img {
  margin: 0px 0px 0px 10px;
  width: 18px;
  height: auto;
}

.playstore-icon-div {
  width: 261px;
  height: 60px;
  border-radius: 6px;
  margin: 111px 0px 0px 0px;
  cursor: pointer;
}

.our-products-app-screnshots-layer {
  width: 1189px;
  height: 1386px;
  margin: -75px 0px 0px 604px;
  /* background-color: #FFFFFF; */
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: row;
  transform: rotate(-30deg);
  /* border: 2px solid #000000; */
}

.our-products-app-screenshot1 {
  width: 270px;
  height: 600px;
  margin: 268px 0px 0px 76px;
  display: flex;
  /* align-items: center; */
}

.our-products-app-screenshot1 img {
  width: 270px;
  height: 600px;
  /* margin: auto 0px 0px; */
}

.our-products-app-screenshot2 {
  width: 270px;
  height: 100%;
  margin: 18px 0px 56px 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-products-app-screenshot2 img {
  width: 270px;
  height: 600px;
  margin: auto 0px;
}

.our-products-section2-container {
  width: 1532px;
  height: 816px;
  margin: 0px auto;
  background-color: #282447;
  display: flex;
}

.our-products-section2-left-div {
  width: 384px;
  height: 687px;
  border: 1px solid #ffffff;
  margin: 80px 0px 0px auto;
}

.our-products-section2-left-div h3 {
  font-size: 36px;
  height: 162px;
  width: 320px;
  margin: 25px auto 0px auto;
  letter-spacing: 0.36px;
  line-height: 56px;
  font-weight: 400;
  color: #ffffff;
}

.our-products-section2-left-div-image {
  width: 100%;
  height: 475px;
  margin: 0px 0px;
}

.our-products-section2-left-div-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.our-products-section2-right-div {
  width: 720px;
  height: 560px;
  /* border: 1px solid #FFFFFF; */
  margin: auto auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-rows: 2fr 2fr;
}

.our-products-section2-right-div-grid {
  width: 200px;
  height: 250px;
  /* margin: 0px 0px 0px 0px; */
  /* border: 1px solid #FFFFFF; */
}

.our-products-section2-right-div-grid1 {
  margin: 0px auto 0px 0px;
}

.our-products-section2-right-div-grid2 {
  margin: 0px auto 0px auto;
}

.our-products-section2-right-div-grid3 {
  margin: 0px 0px 0px auto;
}

.our-products-section2-right-div-grid4 {
  margin: auto auto 0px 0px;
}

.our-products-section2-right-div-grid5 {
  margin: auto auto 0px auto;
}

.our-products-section2-right-div-grid6 {
  margin: auto 0px 0px auto;
}

.our-products-section2-right-div-grid img {
  width: 40px;
  height: 40px;
  margin: 0px;
}

.our-products-section2-right-div-grid h6 {
  width: 100%;
  height: 24px;
  margin: 7px 0px 0px 0px;
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
}

.our-products-section2-right-div-grid p {
  width: 100%;
  height: auto;
  margin: 16px 0px 0px 0px;
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.our-products-section3-container {
  width: 100%;
  height: 974px;
  background-color: #ffffff;
  /* border: 2px solid #000; */
  overflow: hidden;
  position: relative;
}

.our-products-section3-bg-layer {
  width: 1500px;
  height: 1000px;
  border-radius: 24px;
  background: linear-gradient(
    to right,
    rgb(218, 218, 240, 0.32) 22%,
    rgb(55, 52, 190, 0.4) 80%
  );
  opacity: 40%;
  transform: rotate(28.17deg);
  margin: -350px 0px 0px -300px;
  position: absolute;
  z-index: 1;
}

.our-products-section3-content-container {
  width: 1352px;
  height: auto;
  margin: 0px auto;
  /* border: 1px solid rgb(0, 0, 0); */
}

.our-products-section3-content-container h4 {
  width: 734px;
  height: 72px;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin: 70px 0px 0px 0px;
  line-height: 36px;
  letter-spacing: 0.24px;
}

.our-products-section3-content-container p {
  width: 986px;
  height: 108px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(0, 0, 0, 0.7);
  margin: 30px 0px 0px 0px;
  line-height: 36px;
  letter-spacing: 0.2px;
}

.our-products-section3-content-container h2 {
  width: 370px;
  height: 39px;
  font-size: 36px;
  font-weight: bold;
  color: #000000;
  margin: 80px auto 0px auto;
  line-height: 40px;
  letter-spacing: 0.36px;
}

.our-products-section3-grid-container {
  width: 1352px;
  height: 300px;
  margin: 101px auto 0px auto;
  /* border: 1px solid #000; */
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  justify-content: space-between;
}

.our-products-section3-grid {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  z-index: 2;
  box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.15);
}

.our-products-section3-grid h1 {
  font-size: 54px;
  font-weight: 750;
  color: #339999;
  margin: 9px 0px 0px 25px;
  letter-spacing: 0.54px;
  line-height: 65px;
}

.our-products-section3-grid h4 {
  width: auto;
  height: auto;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  margin: 25px 0px 0px 25px;
  letter-spacing: 0.24px;
  line-height: 32px;
}

.our-products-section3-grid p {
  width: 247px;
  height: auto;
  font-size: 18px;
  font-weight: 300;
  color: #000000;
  margin: 20px 0px 0px 25px;
  letter-spacing: 0.18px;
  line-height: 27px;
}

.our-products-section4-container {
  width: 100%;
  height: 905px;
  background: #c8cae2;
  display: flex;
  flex-direction: column;
}

.our-products-section4-container h3 {
  font-size: 36px;
  font-weight: 700;
  color: #000000;
  letter-spacing: 0.36px;
  width: 760px;
  height: 45px;
  line-height: 45px;
  margin: 60px auto 0px auto;
  /* border: 1px solid #000; */
}

.our-products-section4-content {
  width: 1352px;
  height: auto;
  display: flex;
  margin: 70px auto 0px auto;
}

.our-products-section4-left-div {
  width: 500px;
  height: 320px;
  margin: 130px 0px 0px 0px;
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.our-products-section4-left-div p {
  width: 100%;
  height: auto;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin: 0px;
  color: #000000;
}

.our-products-section4-right-div {
  width: 660px;
  height: 660px;
  margin: 0px 0px 0px 192px;
  /* border: 1px solid #000000; */
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 2fr 2fr;
  /* justify-content: space-between; */
}

.our-products-section4-grid {
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.our-products-section4-grid1 {
  border-bottom-right-radius: 30px;
  margin: 0px auto auto 0px;
}

.our-products-section4-grid2 {
  border-bottom-left-radius: 30px;
  margin: 0px 0px auto auto;
}

.our-products-section4-grid3 {
  border-top-right-radius: 30px;
  margin: auto auto 0px 0px;
}

.our-products-section4-grid4 {
  border-top-left-radius: 30px;
  margin: auto 0px 0px auto;
}

.our-products-section4-grid img {
  width: 110px;
  height: 110px;
  margin: 40px auto 0px auto;
}

.our-products-section4-grid h4 {
  width: 224px;
  height: 60px;
  margin: 40px auto 0px auto;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.24px;
  color: #000000;
  font-weight: 600;
  text-align: center;
}

@media only screen and (max-width: 432px) {
  .our-products-container {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .our-products-section1-container {
    width: 100%;
    height: 563px;
    display: flex;
  }

  .our-products-section1-background-image {
    width: 100% !important;
    height: 563px !important;
    margin: 0px;
  }

  .our-products-section1-background-image img {
    width: 100% !important;
    height: 563px !important;
    margin: 0px;
    object-fit: fill;
  }

  .our-products-background-layer {
    width: 100%;
    height: 563px;
    background-color: rgb(0, 0, 0, 0.7);
    margin: 0px;
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .bestbudget-description-container {
    width: 326px !important;
    height: 223px !important;
    margin: 50px auto 0px auto;
    /* border: 3px solid #fdfdfd; */
  }

  .bestbudget-description-container h2 {
    width: 100%;
    height: auto;
    text-align: left;
    margin: 0px 0px;
    font-size: 21px;
    font-family: Impact, sans-serif;
    font-weight: normal;
    letter-spacing: 0.36px;
    line-height: 25px;
    color: #ffffff;
  }

  .bestbudget-description-container p {
    width: 76%;
    height: auto;
    text-align: left;
    margin: 10px 0px 0px 0px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.12px;
    line-height: 18px;
    color: #ffffff;
  }

  .our-products-explore-now-container-button {
    width: 116px;
    height: 30px;
    border-radius: 2px;
    background: linear-gradient(to right, #f0c632 0%, #fa4848cc 80%);
    display: flex;
    margin: 20px 0px 0px 0px;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border: none;
  }

  .our-products-explore-now-container-button p {
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.1px;
    width: auto;
    height: auto;
    text-align: center;
    margin: auto 0px auto 10px;
    color: #ffffff;
  }

  .our-products-explore-now-container-button img {
    margin: auto 0px auto 0px;
    width: 14px;
    height: auto;
  }

  .playstore-icon-div {
    width: 120px !important;
    height: 45px !important;
    display: flex;
    border-radius: 2px;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
  }

  .playstore-icon-div img {
    width: 100%;
    height: 100%;
  }

  .our-products-app-screnshots-layer {
    width: 298px;
    height: 376px;
    margin: 205px 0px 0px 90px;
    /* background-color: #FFFFFF; */
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: row;
    transform: rotate(-30deg);
    /* border: 2px solid #000000; */
  }

  .our-products-app-screenshot1 {
    width: 80px;
    height: 179px;
    margin: 174px 0px 0px 0px;
    display: flex;
    /* align-items: center; */
  }

  .our-products-app-screenshot1 img {
    width: 80px;
    height: 179px;
    /* margin: auto 0px 0px; */
  }

  .our-products-app-screenshot2 {
    width: 80px;
    height: 380px;
    margin: 68px 30px 56px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .our-products-app-screenshot2 img {
    width: 80px;
    height: 179px;
    margin: 25px 0px 0px 0px;
  }

  .our-products-section2-container {
    width: 100%;
    height: 996px;
    margin: 0px auto;
    background-color: #282447;
    display: flex;
    flex-direction: column;
  }

  .our-products-section2-left-div {
    width: 330px;
    height: 409px;
    border: 1px solid #ffffff;
    margin: 30px auto 0px auto;
  }

  .our-products-section2-left-div h3 {
    font-size: 18px;
    height: 54px;
    width: 240px;
    margin: 25px auto 0px auto;
    letter-spacing: 0.18px;
    line-height: 27px;
    font-weight: 400;
    color: #ffffff;
  }

  .our-products-section2-left-div-image {
    width: 289px;
    height: 289px;
    margin: 20px auto 0px auto;
  }

  .our-products-section2-left-div-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .our-products-section2-right-div {
    width: 280px;
    height: 470px;
    /* border: 1px solid #FFFFFF; */
    margin: auto auto;
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 2fr 2fr 2fr;
  }

  .our-products-section2-right-div-grid {
    width: 120px;
    height: 140px;
    /* margin: 0px 0px 0px 0px; */
    /* border: 1px solid #FFFFFF; */
  }

  .our-products-section2-right-div-grid1 {
    margin: 0px auto 0px 0px;
  }

  .our-products-section2-right-div-grid2 {
    margin: 0px 0px 0px auto;
  }

  .our-products-section2-right-div-grid3 {
    margin: auto auto 0px 0px;
  }

  .our-products-section2-right-div-grid4 {
    margin: auto 0px 0px auto;
  }

  .our-products-section2-right-div-grid5 {
    margin: auto auto 0px 0px;
  }

  .our-products-section2-right-div-grid6 {
    margin: auto 0px 0px auto;
  }

  .our-products-section2-right-div-grid h6 {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #ffffff;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
  }

  .our-products-section2-right-div-grid p {
    width: 100%;
    height: auto;
    margin: 10px 0px 0px 0px;
    color: #ffffff;
    text-align: left;
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0.1px;
    line-height: 15px;
  }

  .our-products-section3-container {
    width: 100%;
    height: 656px;
    background-color: #ffffff;
    /* border: 2px solid #000; */
    overflow: hidden;
    position: relative;
  }

  .our-products-section3-bg-layer {
    width: 495px;
    height: 369px;
    border-radius: 24px;
    background: linear-gradient(
      to right,
      rgb(218, 218, 240, 0.32) 22%,
      rgb(55, 52, 190, 0.4) 80%
    );
    opacity: 40%;
    transform: rotate(28.17deg);
    margin: -70px 0px 0px -160px;
    position: absolute;
    z-index: 1;
  }

  .our-products-section3-content-container {
    width: 100%;
    height: auto;
    margin: 0px auto;
    /* border: 1px solid rgb(0, 0, 0); */
  }

  .our-products-section3-content-container h4 {
    width: 320px;
    height: 54px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000000;
    margin: 70px auto 0px auto;
    line-height: 21px;
    letter-spacing: 0.14px;
  }

  .our-products-section3-content-container p {
    width: 256px;
    height: 60px;
    font-size: 10px;
    font-weight: 400;
    color: rgb(0, 0, 0, 0.7);
    margin: 12px 0px 0px 45px;
    line-height: 15px;
    letter-spacing: 0.1px;
    text-align: center;
  }

  .our-products-section3-content-container h2 {
    width: 188px;
    height: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    margin: 37px auto 0px auto;
    line-height: 30px;
    letter-spacing: 0.2px;
  }

  .our-products-section3-grid-container {
    width: 320px;
    height: 320px;
    margin: 50px auto 0px auto;
    /* border: 1px solid #000; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: space-between;
  }

  .our-products-section3-grid {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background: #ffffff;
    z-index: 2;
    box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.15);
  }

  .our-products-section3-grid h1 {
    font-size: 27px;
    font-weight: 750;
    color: #339999;
    margin: 10px 0px 0px 10px;
    letter-spacing: 0.27px;
    line-height: 32px;
  }

  .our-products-section3-grid h4 {
    width: auto;
    height: auto;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    margin: 0px 0px 0px 10px;
    letter-spacing: 0.12px;
    line-height: 18px;
  }

  .our-products-section3-grid p {
    width: 130px;
    height: auto;
    font-size: 10px;
    font-weight: 300;
    color: #000000;
    margin: 10px 0px 0px 10px;
    letter-spacing: 0.1px;
    line-height: 15px;
  }

  .our-products-section4-container {
    width: 100%;
    height: 615px;
    background: #c8cae2;
    display: flex;
    flex-direction: column;
  }

  .our-products-section4-container h3 {
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    letter-spacing: 0.18px;
    width: 276px;
    height: 54px;
    line-height: 27px;
    text-align: center;
    margin: 30px auto 0px auto;
    /* border: 1px solid #000; */
  }

  .our-products-section4-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 20px auto 0px auto;
  }
  .our-products-section4-left-div {
    width: 300px;
    height: 120px;
    margin: 0px auto 0px auto;
    /* border: 1px solid #000; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .our-products-section4-left-div p {
    width: 100%;
    height: auto;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.1px;
    margin: 0px;
    color: #000000;
  }

  .our-products-section4-right-div {
    width: 320px;
    height: 320px;
    margin: 30px auto 0px auto;
    /* border: 1px solid #000000; */
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: 2fr 2fr;
    /* justify-content: space-between; */
  }

  .our-products-section4-grid {
    width: 150px;
    height: 150px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }

  .our-products-section4-grid1 {
    border-bottom-right-radius: 15px;
    margin: 0px auto auto 0px;
  }

  .our-products-section4-grid2 {
    border-bottom-left-radius: 15px;
    margin: 0px 0px auto auto;
  }

  .our-products-section4-grid3 {
    border-top-right-radius: 15px;
    margin: auto auto 0px 0px;
  }

  .our-products-section4-grid4 {
    border-top-left-radius: 15px;
    margin: auto 0px 0px auto;
  }

  .our-products-section4-grid img {
    width: 55px;
    height: 55px;
    margin: 20px auto 0px auto;
  }

  .our-products-section4-grid h4 {
    width: 150px;
    height: auto;
    margin: 20px auto 0px auto;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #000000;
    font-weight: 600;
    text-align: center;
  }
}
